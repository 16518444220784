import { URL_PARAMS } from '@/constants/search/url-param-constants'
import checkIsOnlyOneParam from '@/utilities/search/methods/checkIsOnlyOneParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'
import { getTypeObjectByUrlParamValue } from '@/utilities/search/methods/getTypeObject'
import { getUrlParamValueByName } from '@/utilities/search/methods/getUrlParamValue'

const IS_APPROVED = 'apr'
const IS_MONTAGE_OF_YEAR = 'montofyear'
const IS_MONTAGE_OF_YEAR_NOMINEE = 'montofyearnom'

export function encodeProfStatusParam(filterModule, profStatusModule) {
  const isApproved = filterModule.filterFields[IS_APPROVED]
  const isMontageOfYear = filterModule.filterFields[IS_MONTAGE_OF_YEAR]
  const isMontageOfYearNominee = filterModule.filterFields[IS_MONTAGE_OF_YEAR_NOMINEE]

  const { profStatuses } = profStatusModule

  const isOnlyOneParam = checkIsOnlyOneParam(isApproved, isMontageOfYear, isMontageOfYearNominee)
  if (isOnlyOneParam) {
    if (isApproved) {
      const paramValue = getUrlParamValueByName(IS_APPROVED, profStatuses)
      return { [URL_PARAMS.profStatus]: paramValue }
    }
    if (isMontageOfYear) {
      const paramValue = getUrlParamValueByName(IS_MONTAGE_OF_YEAR, profStatuses)
      return { [URL_PARAMS.profStatus]: paramValue }
    }
    if (isMontageOfYearNominee) {
      const paramValue = getUrlParamValueByName(IS_MONTAGE_OF_YEAR_NOMINEE, profStatuses)
      return { [URL_PARAMS.profStatus]: paramValue }
    }
  }
}
export function decodeProfStatusParam(urlParamsFromQuery, profStatusModule, dispatch) {
  const profStatusParam = urlParamsFromQuery[URL_PARAMS.profStatus]
  if (profStatusParam) {
    const profStatuses = profStatusModule.profStatuses
    const typeObject = getTypeObjectByUrlParamValue(profStatusParam, profStatuses)
    dispatch(
      'pages/search/filter/updateFilterField',
      {
        fieldName: typeObject.name,
        fieldValue: true,
      },
      { root: true },
    )
  }
}
export function computeProfStatusSeo(smartFilterSeoParams, filterModule) {
  const isApproved = filterModule.filterFields[IS_APPROVED]
  const isMontageOfYear = filterModule.filterFields[IS_MONTAGE_OF_YEAR]
  const isMontageOfYearNominee = filterModule.filterFields[IS_MONTAGE_OF_YEAR_NOMINEE]

  const isOnlyOneParam = checkIsOnlyOneParam(isApproved, isMontageOfYear, isMontageOfYearNominee)
  if (isOnlyOneParam) {
    const seoParamValues = getSeoValues(URL_PARAMS.profStatus, smartFilterSeoParams)
    const variable = URL_PARAMS.profStatus.toUpperCase()
    if (isApproved) {
      const isApprovedSeoValue = seoParamValues[0]
      const { h1, title, description } = isApprovedSeoValue
      return { h1, title, description, variable }
    }
    if (isMontageOfYear) {
      const isMontageOfYearSeoValue = seoParamValues[1]
      const { h1, title, description } = isMontageOfYearSeoValue
      return { h1, title, description, variable }
    }
    if (isMontageOfYearNominee) {
      const isMontageOfYearNomineeSeoValue = seoParamValues[2]
      const { h1, title, description } = isMontageOfYearNomineeSeoValue
      return { h1, title, description, variable }
    }
  }
}
