import axios from 'axios'

import { getConfig } from '@/configs/app.configs'
import { getAccessToken } from '@/utilities/access-token-utility'

export class ProfileProvider {
  static async getProfileInfo(performerId) {
    const res = await axios.get(`/api-lkz/performer/${performerId}`, {
      baseURL: getConfig('backUrlLkz'),
      params: { id: performerId },
    })

    return res.data
  }

  static async addPerformerToFavorite(performerId) {
    return await axios.post(
      `/api-lkz/auth/performer/favourite/${performerId}`,
      {},
      {
        baseURL: getConfig('backUrlLkz'),
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
  }

  static async removePerformerToFavorite(performerId) {
    return await axios.delete(`/api-lkz/auth/performer/favourite/${performerId}`, {
      baseURL: getConfig('backUrlLkz'),
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  }
}
