const state = () => ({
  location: [],
})

const actions = {
  updateLocation({ commit, dispatch }, payload) {
    commit('updateLocation', payload)
    // dispatch("search/seoModule/setSeoParams", null, { root: true });
  },
  clearLocation({ commit }) {
    commit('clearLocation')
  },
}

const mutations = {
  updateLocation(state, newLocation) {
    state.location = newLocation
    if (!newLocation) state.location = []
  },
  clearLocation(state) {
    state.location = []
  },
}

const getters = {
  getLocation(state) {
    return state.location
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
