<template>
  <div>
    <NuxtLink :to="to" class="footer-top__logo" @click.native="clickLogo($event, to)">
      <img
        src="@/assets/images/logo/logo-main-white.svg"
        loading="lazy"
        :alt="COMPANY.name"
        class="footer-top__logo-img"
        height="242"
        width="954"
      />
    </NuxtLink>
    <div class="footer-top__copy">{{ copyright }}</div>
  </div>
</template>

<script>
// import { yandexMetrikaReachGoal } from "@/utils/yandex-metrika";
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'FooterLogo',
  data() {
    return {
      COMPANY,
      to: '/',
      copyright: `© ${COMPANY.name}, 2018-${new Date().getFullYear()}`,
    }
  },
  methods: {
    clickLogo(event, to) {
      event.preventDefault()
      // yandexMetrikaReachGoal("ClickCertifiedMontages");
      this.$router.push({ path: to })
    },
  },
}
</script>

<style scoped lang="scss"></style>
