import { URL_PARAMS } from '@/constants/search/url-param-constants'
import { extractSeoParam } from '@/utilities/search/methods/extractSeoParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'

export function encodeFeedbacksParam(filterModule) {
  const feedbacks = filterModule.filterFields.numComments
  const isNeedToUseParam = checkWeNeedUseFeedbacksParam(feedbacks)
  if (isNeedToUseParam) {
    return { [URL_PARAMS.feedbacks]: Math.floor(feedbacks) }
  }
}
export function decodeFeedbacksParam(urlParamsFromQuery, dispatch) {
  const feedbacksParam = urlParamsFromQuery[URL_PARAMS.feedbacks]
  if (feedbacksParam) {
    dispatch(
      'pages/search/filter/updateFilterField',
      {
        fieldName: 'numComments',
        fieldValue: feedbacksParam,
      },
      { root: true },
    )
  }
}
export function computeFeedbacksSeo(smartFilterSeoParams, filterModule) {
  const feedbacks = filterModule.filterFields.numComments
  const isNeedToUseParam = checkWeNeedUseFeedbacksParam(feedbacks)
  if (isNeedToUseParam) {
    const seoParamValues = getSeoValues(URL_PARAMS.feedbacks, smartFilterSeoParams)
    const feedbacksSeoValue = seoParamValues[0]
    const { h1, title, description } = extractSeoParam(feedbacksSeoValue, Math.floor(feedbacks))
    const variable = URL_PARAMS.feedbacks.toUpperCase()
    return { h1, title, description, variable }
  }
}

function checkWeNeedUseFeedbacksParam(param) {
  return param && param > 0
}
