export const SOLUTIONS_SUBMENU = [
  {
    name: 'for-hause-townhouse',
    title: 'Для домов и таунхаусов',
    to: '/resheniya-dlya-domov/',
    toName: 'resheniya-dlya-domov',
  },
  {
    name: 'sl-dzr-latun',
    title: 'DZR латунь',
    to: '/dzr-latun/',
    toName: 'dzr-latun',
  },
  {
    name: 'sl-quality-control',
    title: 'Контроль качества',
    to: '/quality-control/',
    toName: 'quality-control',
  },
  {
    name: 'stabil',
    title: 'Stabil',
    to: '/stabil/',
    toName: 'stabil',
  },
  {
    name: 'sl-r-bonus',
    title: 'R.Bonus',
    to: '/r-bonus/',
    toName: 'r-bonus',
  },
  {
    name: 'sl-education',
    title: 'Обучение и клуб монтажников',
    to: '/education/',
    toName: 'education',
  },
  {
    name: 'sl-r-garant',
    title: 'R.Garant',
    to: '/r-garant/',
    toName: 'r-garant',
  },
]
