import {
  DEFAULT_SORT_MODE,
  ORDER_PARAM_ASC,
  ORDER_PARAM_DESC,
  SORT_PARAM_EXPERIENCE,
  SORT_PARAM_RESPONSE_SPEED,
} from '@/constants/search/search-constants'

const state = () => ({
  sortMode: DEFAULT_SORT_MODE,
  page: 1,
  pagination: {
    currentPage: 1,
    total: 1,
    fromRec: 0,
    toRec: 0,
    totalRec: 0,
  },
})

const actions = {
  updateSortMode({ commit }, payload) {
    commit('updateSortMode', payload)
  },
  updatePage({ commit }, payload) {
    commit('updatePage', payload)
  },
  updatePagination({ commit }, newPagination) {
    commit('updatePagination', newPagination)
  },
  clearSort({ commit }) {
    commit('clearSort')
  },
}

const mutations = {
  updateSortMode(state, newSortMode) {
    if (typeof newSortMode === 'string') {
      switch (newSortMode) {
        case 'experience':
          state.sortMode.sortBy = SORT_PARAM_EXPERIENCE
          if (state.sortMode.sortIndex === ORDER_PARAM_DESC) {
            state.sortMode.sortIndex = ORDER_PARAM_ASC
          } else {
            state.sortMode.sortIndex = ORDER_PARAM_DESC
          }
          break
        case 'responseSpeed':
          state.sortMode.sortBy = SORT_PARAM_RESPONSE_SPEED
          if (state.sortMode.sortIndex === ORDER_PARAM_DESC) {
            state.sortMode.sortIndex = ORDER_PARAM_ASC
          } else {
            state.sortMode.sortIndex = ORDER_PARAM_DESC
          }
          break
      }
    }
  },
  updatePage(state, newPage) {
    state.page = newPage
  },
  updatePagination(state, newPagination) {
    state.pagination = newPagination
  },
  clearSort(state) {
    state.sortMode = DEFAULT_SORT_MODE
    state.page = 1
    state.pagination = {
      currentPage: 1,
      total: 1,
      fromRec: 0,
      toRec: 0,
      totalRec: 0,
    }
  },
}

const getters = {
  getSortClass: (state) => (mode) => {
    switch (mode) {
      // DESC От большего к меньшему ASC От меньшего к большему
      case 'experience':
        if (state.sortMode.sortBy === SORT_PARAM_EXPERIENCE && state.sortMode.sortIndex === ORDER_PARAM_DESC) {
          return 'm_bot_d'
        } else if (state.sortMode.sortBy === SORT_PARAM_EXPERIENCE && state.sortMode.sortIndex === ORDER_PARAM_ASC) {
          return 'm_top_d'
        } else {
          return 'm_both_d'
        }
      case 'responseSpeed':
        if (state.sortMode.sortBy === SORT_PARAM_RESPONSE_SPEED && state.sortMode.sortIndex === ORDER_PARAM_DESC) {
          return 'm_bot_d'
        } else if (
          state.sortMode.sortBy === SORT_PARAM_RESPONSE_SPEED &&
          state.sortMode.sortIndex === ORDER_PARAM_ASC
        ) {
          return 'm_top_d'
        } else {
          return 'm_both_d'
        }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
