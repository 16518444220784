import CyrillicToTranslit from 'cyrillic-to-translit-js'

import { URL_PARAMS } from '@/constants/search/url-param-constants'
import { searchPlaces } from '@/utilities/dadata-utility'
import { extractSeoParam } from '@/utilities/search/methods/extractSeoParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'
const cyrillicToTranslit = new CyrillicToTranslit()

export function encodeCountryParam(locationModule) {
  const country = locationModule.location?.data?.country
  if (country) {
    const countryToEng = cyrillicToTranslit.transform(country, '-')
    return { [URL_PARAMS.country]: countryToEng.toLowerCase() }
  }
}
export async function decodeCountryParam(urlParamsFromQuery, dispatch) {
  const countryParam = urlParamsFromQuery[URL_PARAMS.country]
  if (countryParam) {
    const res = await searchPlaces(countryParam)
    if (res) {
      const resLocation = res[0]
      dispatch('pages/search/location/updateLocation', resLocation, {
        root: true,
      })
    }
  }
}
export function computeCountrySeo(smartFilterSeoParams, locationModule) {
  const country = locationModule.location.data?.country
  if (country) {
    const seoParamValues = getSeoValues(URL_PARAMS.country, smartFilterSeoParams)
    const countrySeoValue = seoParamValues[0]
    const { h1, title, description } = extractSeoParam(countrySeoValue, country)
    const variable = URL_PARAMS.country.toUpperCase()
    return { h1, title, description, variable }
  }
}
