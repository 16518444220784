export function computeSeoTemplate(seoTemplate, seoParamsForTemplate, preparedStringPartsForTemplate) {
  const h1 = computeTotalTemplateString('h1')
  const title = computeTotalTemplateString('title')
  const description = computeTotalTemplateString('description')
  return { h1, title, description }

  function computeTotalTemplateString(stringName) {
    let templateString = seoTemplate[stringName]
    for (const seoParam of seoParamsForTemplate) {
      const variable = seoParam.paramName.toUpperCase()
      const resultOfGettingSeo = getResultOfGettingSeoByVariable(variable, preparedStringPartsForTemplate)
      if (resultOfGettingSeo) {
        templateString = templateString.replace(variable, resultOfGettingSeo[stringName])
      } else {
        templateString = templateString.replace(variable, '')
      }
    }
    const templateWithoutExtraSpaces = templateString.replace(/\s+/g, ' ').trim()
    return templateWithoutExtraSpaces.replace(/\s+([.,!":])/g, '$1').trim()
  }

  function getResultOfGettingSeoByVariable(variable, stringParts) {
    return stringParts.find((result) => result.variable === variable)
  }
}
