const name = 'РЕХАУ'
const nameCapitalize = 'Рeхау'
const subName = 'Про'

const httpsPrefix = 'https://'

const main = 'rhsolutions.ru'
const mainWithHttpsPrefix = `${httpsPrefix}${main}`

const shop = 'voda.shop.rhsolutions.ru'
const shopWithHttpsPrefix = `${httpsPrefix}${shop}`
const vkWithHttpsPrefix = 'https://vk.com/pro.rhsolution'
const tgWithHttpsPrefix = 'https://t.me/prorhsolutions'

const online = 'rehau-online.ru'
const onlineWithHttpsPrefix = `https://maps.rhsolutions.ru/partners/filters`

const email = 'pro@support.rhsolutions.ru'
const emailWithHttpsPrefix = `mailto:${email}`
const mainProWithHttpsPrefix = `${httpsPrefix}pro.${main}`

const phone = {
  label: '+7 (495) 118-44-46',
  link: '84951184446',
}

const phoneSecond = {
  label: '+7 (804) 555-07-97',
  link: 'tel:88045550797',
}

const links = {
  main,
  mainWithHttpsPrefix,
  shop,
  shopWithHttpsPrefix,
  vkWithHttpsPrefix,
  tgWithHttpsPrefix,
  online,
  onlineWithHttpsPrefix,
  email,
  emailWithHttpsPrefix,
  mainProWithHttpsPrefix,
}

export const COMPANY = {
  name,
  subName,
  fullName: `${name}.${subName}`,
  mobileAppName: `${nameCapitalize}.${subName} Качество`,
  links,
  phone,
  phoneSecond,
  email: {
    value: email,
    link: emailWithHttpsPrefix,
  },
}
