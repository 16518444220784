<template>
  <ul class="header__menu">
    <HeaderCatalogItem />
    <li
      v-for="page in pages"
      :key="page.name"
      class="header__menu-item"
      @mouseleave="toggleSubmenuVisibility(page.name, false)"
    >
      <NuxtLink
        v-if="page.to !== ''"
        :to="page.to"
        active-class="-active"
        class="header__menu-link"
        @click.native="clickNavbarLink(page.name)"
      >
        {{ page.title }}
      </NuxtLink>
      <span v-else class="header__menu-link" @mouseenter="toggleSubmenuVisibility(page.name)">
        {{ page.title }}
      </span>
      <ul v-if="page.inner && showSubmenu.includes(page.name)" class="header-submenu submenu">
        <li v-for="inner in page.inner" :key="inner.name" class="submenu__item">
          <NuxtLink :to="inner.to" class="submenu__item-link">{{ inner.title }}</NuxtLink>
        </li>
      </ul>
    </li>
    <li class="header__menu-item">
      <a class="header__menu-link" :href="getConfig('frontUrl') + '/blog/'" target="_blank"> Блог </a>
    </li>
  </ul>
</template>

<script>
// import { countHowItWorks } from "@/utils/counters-api";
// import { yandexMetrikaReachGoal } from "@/utils/yandex-metrika";
// import { gtmReachGoal } from "@/utils/vue-gtm";
import HeaderCatalogItem from '@/components/Header/HeaderCatalogItem.vue'
import { getConfig } from '@/configs/app.configs'
import { FOREIGN_LANDINGS_COUNTRIES } from '@/constants/foreign-landings/foreign-landings.constants'
import { SOLUTIONS_SUBMENU } from '@/constants/solutions/solutions.constants'
export default {
  components: { HeaderCatalogItem },
  data() {
    return {
      showSubmenu: [],
    }
  },
  computed: {
    country() {
      return this.$store.state.pages.foreignLandings.country
    },
    isForeignLanding() {
      return Boolean(this.country)
    },
    pages() {
      return [
        {
          name: 'search',
          title: 'Найти исполнителя',
          to: this.isForeignLanding
            ? {
                name: 'search',
                query: {
                  country: FOREIGN_LANDINGS_COUNTRIES[this.country].fullTitle,
                },
              }
            : '/search/',
        },
        {
          name: 'solutions',
          to: '',
          title: 'Решения',
          inner: SOLUTIONS_SUBMENU,
        },
        {
          name: 'order',
          title: 'Создать заказ',
          to: '/order/',
        },
        {
          name: 'about',
          title: 'О проекте',
          to: '/about/',
        },
      ]
    },
  },
  methods: {
    getConfig,
    toggleSubmenuVisibility(pageName, isShow = true) {
      if (isShow) this.showSubmenu.push(pageName)
      else this.showSubmenu = this.showSubmenu.filter((el) => el !== pageName)
    },
    clickNavbarLink(pageName) {
      switch (pageName) {
        case 'search':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToSearch',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToSearch')
          break
        case 'order':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToOrder',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToOrder')
          break
        case 'ask':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToHow',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToHow')
          // countHowItWorks();
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
// @import '/assets/scss/index/header.scss';
</style>
