export class UrlActionsUtilities {
  /* eslint-disable */
  static converter = {
    'а': 'a','б': 'b','в': 'v','г': 'g','д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
    'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
    'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
    'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
    'э': 'e', 'ю': 'yu', 'я': 'ya',
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
    'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
    'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
    'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
    'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
    'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
    'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya',
  }

  static translitForUrl(string){
    let res = ''
    const stringArr = string.replace(/[.,]/g, ' ').replace(/ {2,}/g, ' ').split(' ').filter(el => el !== '/').map(el => el.replace(/[\/]/g, '-'))
    let replacedString = stringArr.join(' ').toLowerCase().replace(/\s/g,'-').replace(/-{2,}/g, '-')

    if (replacedString.endsWith('-')) {
      replacedString = replacedString.slice(0, -1)
    }

    for (let i = 0; i < replacedString.length; i++) {
      if (this.converter[replacedString[i]] === undefined) {
        // сохраняем все символы, кроме букв
        res += replacedString[i]
      } else {
        res += this.converter[replacedString[i]]
      }
    }

    return res
  }

  static redirectExternal(url){
    window.open(url, '_blank')
  }
}
