const state = () => ({
  name: '',
  object: [],
  objectArea: '',
  specializationTypes: [],
  specializationFields: [],
  wishes: '',
  additionalFields: [],
  files: [],
})

const actions = {
  updateInfoField({ commit }, { fieldName, fieldValue }) {
    commit('updateInfoField', { fieldName, fieldValue })
  },
  saveSpecializationTypes({ state, commit }, specializationTypes) {
    commit('saveSpecializationTypes', specializationTypes)
  },
  createSpecializationFields({ state, commit }, specializationTypes) {
    if (state.specializationFields.length === 0) {
      commit('createSpecializationFields', specializationTypes)
    }
  },
  updateSpecializationField({ commit }, id) {
    commit('updateSpecializationField', id)
  },
  saveAdditionalTypes({ state, commit }, additionalTypes) {
    commit('saveAdditionalTypes', additionalTypes)
  },
  createAdditionalFields({ state, commit }, additionalTypes) {
    if (state.additionalFields.length === 0) {
      commit('createAdditionalFields', additionalTypes)
    }
  },
  updateAdditionalField({ commit }, id) {
    commit('updateAdditionalField', id)
  },
}

const mutations = {
  updateInfoField(state, { fieldName, fieldValue }) {
    state[fieldName] = fieldValue
  },
  saveSpecializationTypes(state, specializationTypes) {
    state.specializationTypes = specializationTypes
  },
  createSpecializationFields(state, specializationTypes) {
    const specializationFields = {}
    for (const specialization of specializationTypes) {
      specializationFields[specialization.id] = false
    }
    state.specializationFields = specializationFields
  },
  updateSpecializationField(state, specId) {
    state.specializationFields[specId] = !state.specializationFields[specId]
  },
  saveAdditionalTypes(state, additionalTypes) {
    state.additionalTypes = additionalTypes
  },
  createAdditionalFields(state, additionalTypes) {
    const additionalFields = {}
    for (const additional of additionalTypes) {
      additionalFields[additional.id] = false
    }
    state.additionalFields = additionalFields
  },
  updateAdditionalField(state, additionalId) {
    state.additionalFields[additionalId] = !state.additionalFields[additionalId]
  },
  updateFilesField(state, files) {
    state.files.push(files)
  },
  removeFiles(state, file) {
    state.files = state.files.filter(function (value) {
      // изменено с !=, возможно не раюотает, проверить
      return value.fileName !== file.name
    })
  },
  removeFileUploadedBefore(state, fileId) {
    state.files = state.files.filter(function (value) {
      return value.id !== fileId
    })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
