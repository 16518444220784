export const ORDER_TITLES = [
  {
    step: 1,
    main: 'Какие виды работ нужно выполнить?',
    sub: 'Отметьте те виды работ, которые необходимы на вашем объекте и под которые вы ищите специалиста',
  },
  {
    step: 2,
    main: 'Где выполнять работы?',
    sub: '',
  },
  {
    step: 3,
    main: 'Что может потребоваться?',
    sub: '',
  },
  {
    step: 4,
    main: 'Остались пожелания к заявке?',
    sub: 'Подробно опишите суть и особенности проекта: сроки начала и завершения работ, особенности подъезда строительной техники, наличие подведенных коммуникаций, наличие чертежей и тд.',
  },
  {
    step: 5,
    main: 'Прикрепите материалы',
    sub: 'Вы можете загрузить файлы *.pdf, *.jpeg, *.png, *.webp или прикрепить ссылки со схемами, чертежами, фотографиями, проектами и прочими материалами для пояснения сути заявки',
  },
  {
    step: 6,
    main: 'Когда нужно начать работы?',
    sub: 'Укажите дату или период начала работ',
  },
  {
    step: 7,
    main: 'Кого пригласить исполнителем?',
    sub: 'Выберите из списка до 5 подходящих специалистов. Каждому из них мы отправим ваш проект на ознакомление, и они свяжутся с вами.',
  },
  {
    step: 8,
    main: 'Как назвать заявку?',
    sub: '',
  },
]

export const FIRST_STEP = 1
export const LAST_STEP = 8

export const DEFAULT_ORDER_FORM = {
  step: 1,
  specializations: [],
  objectVariant: 'new',
  existObject: {},
  newObject: {
    objectTypes: {},
    objectArea: '',
    location: {},
  },
  additionalWorks: [],
  wishes: '',
  files: [],
  links: [],
  date: {
    start: '',
    end: '',
  },
  installersVariant: 'recommended',
  selectedInstallers: [],
  name: '',
  isNeedToSaveAfterLoginAsClient: false,
}

export const DEFAULT_ORDER_PAGE_SIZE = 10

export const ORDER_SELECTED_INSTALLERS_MAX_AMOUNT = 5
