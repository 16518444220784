const state = () => ({})

const actions = {
  saveOrderFields({ rootState }) {
    const {
      name,
      object,
      objectArea,
      specializationTypes,
      specializationFields,
      wishes,
      additionalTypes,
      additionalFields,
      files,
    } = rootState.pages.order.stepOneInfo
    const stepOneDetails = rootState.pages.order.stepOneDetails
    const stepTwoInstallers = rootState.pages.order.stepTwoInstallers
    const objectToSave = {
      stepOneInfo: {
        name,
        object,
        objectArea,
        specializationTypes,
        specializationFields,
        wishes,
        additionalTypes,
        additionalFields,
        files,
      },
      stepOneDetails,
      stepTwoInstallers,
    }
    localStorage.setItem('stepOneFields', JSON.stringify(objectToSave))
    const now = new Date().getTime()
    localStorage.setItem('orderExpires', now)
  },
  parseOrderFields({ commit }) {
    const stepOneFields = JSON.parse(localStorage.getItem('stepOneFields'))

    if (stepOneFields) {
      const now = new Date().getTime()
      const hours = 24
      if (!localStorage.orderExpires || now - localStorage.orderExpires < hours * 60 * 60 * 1000) {
        const stepOneInfo = stepOneFields.stepOneInfo
        for (const infoField in stepOneInfo) {
          commit('stepOneInfo/updateInfoField', {
            fieldName: infoField,
            fieldValue: stepOneInfo[infoField],
          })
        }
        const stepOneDetails = stepOneFields.stepOneDetails
        for (const detailsField in stepOneDetails) {
          commit('stepOneDetails/updateDetailsField', {
            fieldName: detailsField,
            fieldValue: stepOneDetails[detailsField],
          })
        }
        const stepTwoInstallers = stepOneFields.stepTwoInstallers
        commit('stepTwoInstallers/updateChosenInstaller', stepTwoInstallers.chosenInstallers)
      } else {
        localStorage.removeItem('stepOneFields')
      }
    }
  },
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
