<template>
  <div class="footer-top__contacts">
    <a :href="'tel:' + tel.link" class="footer-top__tel ui-h4">{{ tel.label }}</a>
    <div v-if="isForeignLanding" class="footer-top__tel-text">
      *Звонки по России и в страны СНГ (Беларусь и Казахстан)
    </div>
    <div class="second-phone-container">
      <a :href="secondTel.link" class="footer-top__tel ui-h4">{{ secondTel.label }}</a>
      <div class="footer-top__tel-text">*Звонки по России бесплатные</div>
    </div>
    <div class="footer-top__address ui-body-small">
      {{ address }}
    </div>
    <ul class="footer-top__social">
      <li v-for="social in socials" :key="social.url" class="footer-top__social-item">
        <a :href="social.url" target="_blank" class="footer-top__social-link" @click="clickFooterLink(social.url)">
          <img :src="social.logoSrc" :alt="social.alt" loading="lazy" height="24" width="24" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'FooterContacts',
  data() {
    return {
      tel: COMPANY.phone,
      secondTel: COMPANY.phoneSecond,
      address: 'г. Москва, ул. Нагорная, дом 3А, 117186, Россия',
      socials: {
        vk: {
          url: COMPANY.links.vkWithHttpsPrefix,
          logoSrc: require('@/assets/images/logo/logo-vk.svg'),
          alt: `${COMPANY.fullName} Вконтакте`,
        },
        telegram: {
          url: COMPANY.links.tgWithHttpsPrefix,
          logoSrc: require('@/assets/images/logo/logo-telegram.svg'),
          alt: `${COMPANY.fullName} в Telegram`,
        },
        // instagram: {
        //   url: 'https://www.instagram.com/rehau.pro/?hl=ru',
        //   logoSrc: require('@/assets/images/logo/logo-instagram.svg'),
        // },
      },
    }
  },
  computed: {
    isForeignLanding() {
      return Boolean(this.$store.state.pages.foreignLandings.country)
    },
  },
  methods: {
    clickFooterLink(url) {
      switch (url) {
        case COMPANY.links.vkWithHttpsPrefix:
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickFooterLinkVk',
          })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkVk')
          break
        case COMPANY.links.tgWithHttpsPrefix:
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickFooterLinkTg',
          })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkTg')
          break
        // case 'https://www.instagram.com/rehau.pro/?hl=ru':
        //   this.$gtm.push({
        //     event: 'reachGoal',
        //     target: 'Goals',
        //     action: 'ClickFooterLinkIg',
        //   })
        //   this.$yandexMetrika.reachGoal('ClickFooterLinkIg')
        //   break
      }
    },
  },
}
</script>

<style scoped lang="scss">
.second-phone-container {
  display: flex;
  flex-direction: column;

  a {
    width: fit-content;
  }
}
</style>
