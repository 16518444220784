<template>
  <li class="header__menu-item" @mouseleave="closeMenu">
    <a :href="getConfig('frontUrl') + '/catalog/'" class="header__menu-link" @mouseenter="onEnterMainLink">Каталог</a>
    <ul v-if="catalogStore.menu && showSubmenu" class="header-submenu submenu">
      <li v-for="item in catalogStore.menu" :key="item.id" class="submenu__item">
        <a
          :href="item.fullPath"
          class="submenu__item-link"
          :class="{ '-active': submenuInnerId === item.id }"
          @mouseenter="submenuInnerId = item.id"
        >
          {{ item.name }}
        </a>
        <ul v-if="item.children && submenuInnerId === item.id" class="header-submenu submenu inner">
          <li v-for="childItem in item.children" :key="childItem.id" class="submenu__item">
            <a :href="childItem.fullPath" class="submenu__item-link">
              {{ childItem.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script>
import { computed, defineComponent, onMounted, ref, useRoute } from '@nuxtjs/composition-api'

import { getConfig } from '@/configs/app.configs'
import { useCatalogStore } from '@/store/modules/catalog.store'

export default defineComponent({
  name: 'HeaderCatalogItem',
  setup() {
    const route = useRoute()
    const catalogStore = useCatalogStore()

    const fullPath = computed(() => route.value.fullPath)
    const showSubmenu = ref(false)
    const submenuInnerId = ref(null)

    onMounted(() => catalogStore.getCatalogMenu())

    const closeMenu = () => {
      showSubmenu.value = false
      submenuInnerId.value = null
    }

    const onEnterMainLink = () => {
      showSubmenu.value = true
    }

    return { catalogStore, fullPath, showSubmenu, submenuInnerId, closeMenu, getConfig, onEnterMainLink }
  },
})
</script>

<style scoped lang="scss"></style>
