const state = () => ({
  profStatuses: [],
})

const actions = {
  async fetchProfStatuses({ commit }) {
    const res = await this.$axios.get(`/custapi/allrest/search/profstatuses`)
    const fetchedProfStatuses = await res.data
    commit('fetchProfStatuses', fetchedProfStatuses)
  },
}

const mutations = {
  fetchProfStatuses(state, fetchedProfStatuses) {
    state.profStatuses = fetchedProfStatuses
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
