<template>
  <footer class="footer">
    <div class="footer__top footer-top">
      <div class="container">
        <div class="footer-top__row">
          <div class="footer-top__col -logo">
            <FooterLogo />
          </div>
          <div class="footer-top__col -menu">
            <FooterNavbar />
          </div>
          <div class="footer-top__col -contacts">
            <FooterContacts />
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom footer-bottom">
      <div class="container">
        <FooterLink class="public-footer-link" />
      </div>
    </div>
  </footer>
</template>

<script>
import FooterContacts from '@/components/Footer/FooterContacts'
import FooterLink from '@/components/Footer/FooterLink'
import FooterLogo from '@/components/Footer/FooterLogo'
import FooterNavbar from '@/components/Footer/FooterNavbar'

export default {
  components: {
    FooterContacts,
    FooterLink,
    FooterLogo,
    FooterNavbar,
  },
}
</script>

<style lang="scss">
@import '/assets/scss/footer.scss';
</style>
