import Keycloak from 'keycloak-js'

import { getConfig } from '@/configs/app.configs'
import { setAccessToken } from '@/utilities/access-token-utility'

export default async ({ app }, inject) => {
  const keycloak = new Keycloak({
    realm: 'rehau',
    url: getConfig('keycloakUrl'),
    clientId: 'lkm',
  })

  keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  })

  keycloak.onReady = (authenticated) => {
    if (!authenticated) return

    setAccessToken(keycloak.token)

    setInterval(async () => {
      // Обновляем токен, если срок его действия истекает в течение 70 секунд
      await keycloak.updateToken(70).catch((e) => e)
    }, 10000)
  }

  keycloak.onAuthRefreshSuccess = () => {
    setAccessToken(keycloak.token)
  }

  inject('keycloak', keycloak)
}
