const address = 'address'
const object = 'object'
const experience = 'experience'
const feedbacks = 'feedbacks'
const installerType = 'installerType'
const specialization = 'specialization'
const profStatus = 'profStatus'
const country = 'country'

export const URL_PARAMS = {
  address,
  object,
  experience,
  feedbacks,
  installerType,
  specialization,
  profStatus,
  country,
}

// const SORT = {
//   key: 'sort',
//   byExp: 'opyt',
//   bySpeed: 'skorost',
// }
// const ORDER = {
//   key: 'order',
//   asc: 'ASC',
//   desc: 'DESC',
// }
// const PAGE = {
//   key: 'pagen',
// }
// export const URL_QUERIES = {
//   ADDRESS,
//   EXPERIENCE,
//   FEEDBACKS,
//   INSTALLER_TYPE,
//   OBJECT,
//   SPECIALIZATION,
//   PROF_STATUS,
//   SORT,
//   ORDER,
//   PAGE,
// }
