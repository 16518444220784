export async function retardedPagination({ request, totalPagesField = 'totalPages' }, ...args) {
  let page = 1
  const res = await request(page, ...args)
  page += 1
  const lastPage = res[totalPagesField]
  const promises = []
  for (; page <= lastPage; page++) {
    promises.push(request(page, ...args))
  }
  const allRes = await Promise.all(promises)
  return [res, ...allRes]
}
