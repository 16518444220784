export default function ({ route, redirect }) {
  if (route.fullPath === '/') return

  const routeHasQuery = route.fullPath.includes('?')
  let toPath

  if (routeHasQuery) {
    const splitPath = route.fullPath.split('?')

    toPath = !splitPath[0].endsWith('/') ? splitPath[0] + '/?' + splitPath[1] : null
  } else {
    toPath = !route.fullPath.endsWith('/') ? route.fullPath + '/' : null
  }

  if (toPath) {
    redirect(301, toPath)
  }
}
