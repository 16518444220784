const SEO_TEMPLATE_PARAM_VALUE = 'VALUE'

export function extractSeoParam(seoParamObject, value) {
  const h1 = replaceSeoParamString(seoParamObject.h1, value)
  const title = replaceSeoParamString(seoParamObject.title, value)
  const description = replaceSeoParamString(seoParamObject.description, value)

  return { h1, title, description }

  function replaceSeoParamString(seoParamString, valueForReplace) {
    return seoParamString.replace(SEO_TEMPLATE_PARAM_VALUE, valueForReplace)
  }
}
