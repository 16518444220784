export const state = () => ({
  step: 1,
  form: {
    city: '',
    service: '',
    phone: '',
    name: '',
    object: '',
    size: '',
    type: 'ASK_QUESTION',
  },
})

export const actions = {
  updateFormField({ commit }, { fieldName, fieldValue }) {
    commit('updateFormField', { fieldName, fieldValue })
  },
  iterateStep({ commit }) {
    commit('iterateStep')
  },
  resetAll({ commit }) {
    commit('resetAll')
  },
}

export const mutations = {
  updateFormField(state, { fieldName, fieldValue }) {
    state.form[fieldName] = fieldValue
  },
  iterateStep(state) {
    state.step = state.step += 1
  },
  resetAll(state) {
    state.form = {
      city: '',
      service: '',
      phone: '',
      name: '',
      object: '',
      size: '',
      type: 'ASK_QUESTION',
    }
    state.step = 1
  },
}

export const getters = {}
