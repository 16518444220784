import { URL_PARAMS } from '@/constants/search/url-param-constants'
import checkIsOnlyOneParam from '@/utilities/search/methods/checkIsOnlyOneParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'
import { getTypeObjectByUrlParamValue } from '@/utilities/search/methods/getTypeObject'
import { getUrlParamValueByName } from '@/utilities/search/methods/getUrlParamValue'

const IS_PHYSICAL = 'phisical'
const IS_IP = 'isip'
const IS_ORG = 'org'

export function encodeInstallerTypeParam(filterModule, installerTypeModule) {
  const isPhysical = filterModule.filterFields[IS_PHYSICAL]
  const isIp = filterModule.filterFields[IS_IP]
  const isOrg = filterModule.filterFields[IS_ORG]

  const { installerTypes } = installerTypeModule

  const isOnlyOneParam = checkIsOnlyOneParam(isPhysical, isIp, isOrg)
  if (isOnlyOneParam) {
    if (isPhysical) {
      const paramValue = getUrlParamValueByName(IS_PHYSICAL, installerTypes)
      return { [URL_PARAMS.installerType]: paramValue }
    }
    if (isIp) {
      const paramValue = getUrlParamValueByName(IS_IP, installerTypes)
      return { [URL_PARAMS.installerType]: paramValue }
    }
    if (isOrg) {
      const paramValue = getUrlParamValueByName(IS_ORG, installerTypes)
      return { [URL_PARAMS.installerType]: paramValue }
    }
  }
}
export function decodeInstallerTypeParam(urlParamsFromQuery, installerTypeModule, dispatch) {
  const installerTypeParam = urlParamsFromQuery[URL_PARAMS.installerType]
  if (installerTypeParam) {
    const installerTypes = installerTypeModule.installerTypes
    const typeObject = getTypeObjectByUrlParamValue(installerTypeParam, installerTypes)
    dispatch(
      'pages/search/filter/updateFilterField',
      {
        fieldName: typeObject.name,
        fieldValue: true,
      },
      { root: true },
    )
  }
}
export function computeInstallerTypeSeo(smartFilterSeoParams, filterModule) {
  const isPhysical = filterModule.filterFields[IS_PHYSICAL]
  const isIp = filterModule.filterFields[IS_IP]
  const isOrg = filterModule.filterFields[IS_ORG]

  const isOnlyOneParam = checkIsOnlyOneParam(isPhysical, isIp, isOrg)
  if (isOnlyOneParam) {
    const seoParamValues = getSeoValues(URL_PARAMS.installerType, smartFilterSeoParams)
    const variable = URL_PARAMS.installerType.toUpperCase()
    if (isPhysical) {
      const isPhysicalSeoValue = seoParamValues[0]
      const { h1, title, description } = isPhysicalSeoValue
      return { h1, title, description, variable }
    }
    if (isIp) {
      const isIpSeoValue = seoParamValues[1]
      const { h1, title, description } = isIpSeoValue
      return { h1, title, description, variable }
    }
    if (isOrg) {
      const isOrgSeoValue = seoParamValues[2]
      const { h1, title, description } = isOrgSeoValue
      return { h1, title, description, variable }
    }
  }
}
