export const SORT_QUERY_PARAM = 'sort'
export const ORDER_QUERY_PARAM = 'order'
export const PAGE_QUERY_PARAM = 'pagen'

export const SORT_PARAM_EXPERIENCE = 'BY_EXPERIENCE'
export const SORT_PARAM_RESPONSE_SPEED = 'BY_REPLY_SPEED'

//  Пока что нигде не используються, но в АПИ имеються так что пусть полежат тут
export const SORT_PARAM_BY_ID = 'BY_ID'
export const SORT_PARAM_BY_RATING = 'BY_RATING'

export const ORDER_PARAM_ASC = 'ASC'
export const ORDER_PARAM_DESC = 'DESC'

export const DEFAULT_SORT_MODE = { sortBy: SORT_PARAM_RESPONSE_SPEED, sortIndex: ORDER_PARAM_DESC }

const PERFORMER_INSTALLER = {
  key: 'PHYSICAL',
  name: 'Монтажник',
}

const PERFORMER_INSTALLER_WITH_YURIDIC = {
  key: 'PHYSICALWITHYURIDIC',
  name: 'Монтажник с ИП',
}

const PERFORMER_INSTALLER_ORG = {
  key: 'YURIDIC',
  name: 'Монтажная организация',
}

export const PERFORMER_TYPES = [PERFORMER_INSTALLER, PERFORMER_INSTALLER_WITH_YURIDIC, PERFORMER_INSTALLER_ORG]

const APPROVED_PARTNER = {
  key: 'APPROVED_PARTNER',
  name: 'Авторизованный партнер',
}

const MONTAGE_OF_YEAR = {
  key: 'MONTAGE_OF_YEAR',
  name: 'Победитель премии “Монтажник Года”',
}

const NOM_MONTAGE_OF_YEAR = {
  key: 'NOM_MONTAGE_OF_YEAR',
  name: 'Номинант премии “Монтажник Года”',
}

export const PROFESSIONAL_STATUSES = [APPROVED_PARTNER, MONTAGE_OF_YEAR, NOM_MONTAGE_OF_YEAR]
