<template>
  <Nuxt />
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '/assets/scss/general.scss';
@import '/assets/scss/utilities.scss';
</style>
