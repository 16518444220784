export default () => ({
  modals: {
    success: false,
    error: false,
    chosenInstallersSendFormModal: false,
    personalDataConsent: false,
    addToFavoritesLoginModal: false,
    login: false,
    map: false,
    needAuthForMakeOrder: false,
    successOrderModal: false,
    qrSuccessModal: false,
  },
})
