const state = () => ({
  filterVisible: true,
  filterFields: {
    name: '',
    experienceYears: 0,
    reviewsCount: 0,
    performerTypes: [],
    specializations: [],
    competences: [],
    professionalStatuses: [],
    datesRange: '',
  },
})

const actions = {
  toggleFilter({ commit }) {
    commit('toggleFilter')
  },
  updateFilterField({ commit, dispatch }, payload) {
    commit('updateFilterField', payload)
    // dispatch("publicSearch/seoModule/setSeoParams", null, { root: true });
  },
  updateFilterCompetenceField({ commit, dispatch }, payload) {
    commit('updateFilterCompetenceField', payload)
    // dispatch("publicSearch/seoModule/setSeoParams", null, { root: true });
  },
  updateFilterSpecializationField({ commit, dispatch }, payload) {
    commit('updateFilterSpecializationField', payload)
    // dispatch("publicSearch/seoModule/setSeoParams", null, { root: true });
  },
  clearFilter({ commit, dispatch }) {
    commit('clearFilter')
    // dispatch("doSearch");
  },
}

const mutations = {
  toggleFilter(state) {
    state.filterVisible = !state.filterVisible
  },
  updateFilterField(state, { fieldName, fieldValue }) {
    state.filterFields[fieldName] = fieldValue
  },
  updateFilterSpecializationField(state, specId) {
    const specializations = state.filterFields.specializations
    specializations[specId] = !specializations[specId]
    state.filterFields.specializations = specializations
  },
  updateFilterCompetenceField(state, compId) {
    state.filterFields.competences[compId] = !state.filterFields.competences[compId]
  },
  clearFilter(state) {
    state.filterFields = {
      name: '',
      experienceYears: 0,
      reviewsCount: 0,
      performerTypes: [],
      specializations: [],
      competences: [],
      professionalStatuses: [],
      datesRange: '',
    }
  },
}

const getters = {
  getFilterVisibleName: (state) => {
    if (state.filterVisible) {
      return 'Скрыть фильтр'
    } else {
      return 'Показать фильтр'
    }
  },
  getFilterStyle: (state) => {
    if (state.filterVisible) {
      return 'transform: rotate(90deg);'
    } else {
      return 'transform: rotate(0deg);'
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
