import { isEmpty } from 'lodash'

import { computeSeoParams } from '@/utilities/search/methods/computeSeoParams'
import { extractDefaultSeoParams } from '@/utilities/search/methods/getDefaultSeoParams'

const state = () => ({
  currentSeoParams: {
    h1: '',
    description: '',
    title: '',
  },
  defaultSeoParams: {},
  seoTemplate: {},
  seoParamsForTemplate: [],
})

const actions = {
  async fetchAllSeoParams({ dispatch }) {
    await dispatch('fetchDefaultSeoParams')
    await dispatch('fetchSeoTemplate')
    await dispatch('fetchSeoParamsForTemplate')
  },
  async fetchDefaultSeoParams({ commit }) {
    const res = await this.$axios.$get('/custapi/allrest/contents/listcontents', {
      params: { contPage: 'SEARCH', page: '1' },
    })
    const extractedParams = extractDefaultSeoParams(res)
    commit('updateSeoParams', extractedParams)
    commit('fetchDefaultSeoParams', extractedParams)
  },
  async fetchSeoTemplate({ commit }) {
    const res = await this.$axios.$get('/custapi/allrest/search/totaltemplate')
    commit('fetchSeoTemplate', res)
  },
  async fetchSeoParamsForTemplate({ commit }) {
    const res = await this.$axios.$get('/custapi/allrest/search/smartfilterparams')
    commit('fetchSeoParamsForTemplate', res)
  },
  setSeoParams({ state, rootState, commit }) {
    // const { smartFilterSeoParams } = state
    // const { totalTemplate } = state
    const computedSeoParams = computeSeoParams(rootState.pages.search)
    if (!isEmpty(computedSeoParams)) {
      commit('updateSeoParams', computedSeoParams)
    } else {
      commit('updateSeoParams', state.defaultSeoParams)
    }
    // if (state.title) {
    //   setDocumentTitle(state.title)
    // }
    // if (state.description) {
    //   setDocumentDescription(state.description)
    // }
  },
}

const mutations = {
  fetchDefaultSeoParams(state, seoParams) {
    state.defaultSeoParams = seoParams
  },
  updateSeoParams(state, seoParams) {
    state.currentSeoParams = seoParams
  },
  fetchSeoTemplate(state, seoTemplate) {
    state.seoTemplate = seoTemplate
  },
  fetchSeoParamsForTemplate(state, seoParamsForTemplate) {
    state.seoParamsForTemplate = seoParamsForTemplate
  },
  // updateObject(state, newObject) {
  //   state.object = newObject;
  // },
  // clearObject(state) {
  //   state.object = [];
  // }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
