import { URL_PARAMS } from '@/constants/search/url-param-constants'
import { extractSeoParam } from '@/utilities/search/methods/extractSeoParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'

export function encodeExperienceParam(filterModule) {
  const experience = filterModule.filterFields.numYearsExp
  const isNeedToUseParam = checkWeNeedUseExperienceParam(experience)
  if (isNeedToUseParam) {
    return { [URL_PARAMS.experience]: Math.floor(experience) }
  }
}
export function decodeExperienceParam(urlParamsFromQuery, dispatch) {
  const experienceParam = urlParamsFromQuery[URL_PARAMS.experience]
  if (experienceParam) {
    dispatch(
      'pages/search/filter/updateFilterField',
      {
        fieldName: 'numYearsExp',
        fieldValue: experienceParam,
      },
      { root: true },
    )
  }
}
export function computeExperienceSeo(smartFilterSeoParams, filterModule) {
  const experience = filterModule.filterFields.numYearsExp
  const isNeedToUseParam = checkWeNeedUseExperienceParam(experience)
  if (isNeedToUseParam) {
    const seoParamValues = getSeoValues(URL_PARAMS.experience, smartFilterSeoParams)
    const experienceSeoValue = seoParamValues[0]
    const { h1, title, description } = extractSeoParam(experienceSeoValue, Math.floor(experience))
    const variable = URL_PARAMS.experience.toUpperCase()
    return { h1, title, description, variable }
  }
}

function checkWeNeedUseExperienceParam(param) {
  return param && param > 0
}
