<template>
  <div class="logged-in">
    <div class="row" @click="isShowMenu = !isShowMenu">
      <div class="user-name">
        {{ wordCutter(userStore.data.fullName, 25) }}
      </div>

      <transition name="menuList">
        <ul v-if="isShowMenu" class="menu-list" :class="{ 'menu-list-reverse': reverse }">
          <li v-for="item in menuList" :key="item.title" class="menu-item">
            <a v-if="item.toUrl" :href="item.toUrl" style="display: block; height: 100%; width: 100%">
              {{ item.title }}
            </a>
          </li>
          <li class="menu-item" @click.prevent="logout()">
            <a> Выйти </a>
          </li>
        </ul>
      </transition>

      <div class="menu-icon">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :transform="`rotate(${arrowRotate} 0 0)`"
        >
          <g clip-path="url(#clip0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.00002 11.2269L16.3019 4L18 5.68067L9.00002 14.5882L-7.34644e-08 5.68067L1.69811 4L9.00002 11.2269Z"
              fill="#dc143c"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@nuxtjs/composition-api'

import { getConfig } from '@/configs/app.configs'
import { COMPANY } from '@/constants/global.constant'
import { useUserStore } from '@/store/modules/user.store'
import { wordCutter } from '@/utilities/word-cutter'

export default {
  name: 'UserLoggedIn',
  props: {
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const userStore = useUserStore()
    const isShowMenu = ref(false)

    const arrowRotate = computed(() => {
      return isShowMenu.value ? 180 : 0
    })

    const isShowLoyaltyMenu = computed(() => {
      return false
    })

    const menuList = computed(() => {
      if (userStore.isInstaller) {
        const list = [
          {
            toUrl: getConfig('frontUrl') + '/lkm-front',
            title: 'Профиль',
          },
        ]
        if (isShowLoyaltyMenu.value) {
          list.push({
            toUrl: getConfig('frontUrl') + '/lkm-front' + '/loyalty',
            title: 'Программа лояльности',
          })
        }
        return list
      }
      if (userStore.isClient) {
        return [
          {
            toUrl: getConfig('oldPublicUrl') + '/client/profile',
            title: 'Профиль',
          },
        ]
      }
      if (userStore.isWorker) {
        return [
          {
            toUrl: getConfig('oldPublicUrl') + '/worker',
            title: 'Сотрудникам',
          },
        ]
      }
      return []
    })

    return { userStore, isShowMenu, COMPANY, arrowRotate, menuList }
  },
  methods: {
    wordCutter,
    logout() {
      useUserStore().logout()
      this.$keycloak.logout()
      // this.$keycloak.clearToken()
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.logged-in {
  cursor: pointer;
}
.row {
  display: flex;
  align-items: center;
  position: relative;
}
.user-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 44px;
  white-space: nowrap;
  color: $active-red;
  margin-right: 16px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    margin-right: 10px;
  }
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    transition: 0.3s;
  }
}
.menu-list {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-sizing: border-box;
  border: 1px solid $tint;
  position: absolute;
  top: 44px;
  right: 0;
  //width: auto;
  //min-width: 290px;
  width: 290px;
  list-style: none;
}
.menu-list-reverse {
  top: calc(-100% - 94px);
  left: 0;
}

.menuList-enter-active,
.menuList-leave-active {
  transition: opacity 0.3s;
}
.menuList-enter,
.menuList-leave-to {
  opacity: 0;
}
.menu-item {
  line-height: 44px;
  color: $active-red;
  text-decoration: none;
  padding: 0 19px;

  &:last-child {
    border-top: 1px solid $tint;
  }

  &:hover,
  &:focus {
    outline: none;
    background-color: $black-20;
  }
  > a,
  a:visited,
  a:link {
    all: unset;
  }
}
</style>
