<template>
  <div class="header__logo" @click="clickLogo">
    <span class="header__logo-img-wrap">
      <img
        src="@/assets/images/logo/logo-main.svg"
        :alt="COMPANY.name"
        class="header__logo-img"
        height="242"
        width="954"
      />
    </span>
  </div>
</template>

<script>
import { COMPANY } from '@/constants/global.constant'

export default {
  data() {
    return {
      COMPANY,
    }
  },
  methods: {
    clickLogo() {
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style scoped lang="scss">
// @import "/assets/scss/index/header.scss";
</style>
