export function fillSearchResultsObject(searchResult) {
  const res = searchResult

  return {
    total: res.total,
    montageList: res.content,
  }
}

export function fillPaginationObject(searchResult) {
  const { id, firstItem, lastItem, total, lastPage } = searchResult
  return {
    currentPage: id,
    fromRec: firstItem,
    toRec: lastItem,
    totalRec: total,
    total: lastPage,
  }
}
