// import locationModule from "@/store/pages/search/location";
// import objectModule from "@/store/pages/search/object";
// import filterModule from "@/store/pages/search/SearchFilter";
// import specializationsModule from "@/store/pages/search/specializations";
// import profStatusModule from "@/store/pages/search/profStatus";
// import installerTypeModule from "@/store/pages/search/installerType";

import { cloneDeep } from 'lodash'

import { getConfig } from '@/configs/app.configs'
import { useUserStore } from '@/store/modules/user.store'
import { getAccessToken } from '@/utilities/access-token-utility'
import { fillPaginationObject, fillSearchResultsObject } from '@/utilities/search/methods/fillSearchResults'
import getSearchParams from '@/utilities/search/methods/getSearchParams'

const defaultState = {
  searchResult: {
    total: 0,
    montageList: [],
    experienceYears: 0,
    reviewsCount: 0,
    phisical: 0,
    isip: 0,
    org: 0,
  },
  searchResultLoading: true,
}
export const state = () => defaultState
export const actions = {
  clearState({ commit }) {
    commit('clearState')
  },
  async doSearch({ state, commit, dispatch }) {
    dispatch('updateSearchResultLoading', true)

    const searchParams = getSearchParams(state)

    const accessToken = getAccessToken()
    const isClient = useUserStore().isClient

    let searchResult = []
    if (accessToken && isClient) {
      searchResult = await this.$axios.$get('/api-lkz/auth/performer', {
        baseURL: getConfig('backUrlLkz'),
        params: searchParams,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } else {
      searchResult = await this.$axios.$get('/api-lkz/performer', {
        baseURL: getConfig('backUrlLkz'),
        params: searchParams,
      })
    }

    commit('fillSearchResults', searchResult)

    const paginationObject = fillPaginationObject(searchResult)
    dispatch('pages/search/sort/updatePagination', paginationObject, {
      root: true,
    })
    dispatch('updateSearchResultLoading', false)
  },
  async doSearchWithEncode({ dispatch }) {
    const isOrderStepTwoPage = this.$router.currentRoute.path.includes('/order/step-two')

    if (!isOrderStepTwoPage) {
      await dispatch('pages/search/urlParams/encodeUrlParams', null, {
        root: true,
      })
    }

    await dispatch('doSearch')

    if (!isOrderStepTwoPage) {
      await dispatch('pages/search/seo/setSeoParams', null, {
        root: true,
      })
    } else {
      dispatch('pages/search/sort/updatePage', 1, { root: true })
    }
  },
  async doSearchWithDecode({ dispatch }, urlParamsFromQuery) {
    await dispatch('pages/search/urlParams/decodeUrlParams', urlParamsFromQuery, {
      root: true,
    })
    await dispatch('doSearch')
    await dispatch('pages/search/seo/setSeoParams', null, {
      root: true,
    })
  },
  updateSearchResultLoading({ commit }, payload) {
    commit('updateSearchResultLoading', payload)
  },
  clearAllSearchModules({ dispatch }) {
    dispatch('pages/search/location/clearLocation', null, {
      root: true,
    })
    dispatch('pages/search/object/clearObject', null, {
      root: true,
    })
    dispatch('pages/search/filter/clearFilter', null, {
      root: true,
    })
  },
  async fetchAllDataForSearch({ dispatch }) {
    await dispatch('pages/search/object/fetchObjectTypes', null, {
      root: true,
    })
    await dispatch('pages/search/installerType/fetchInstallerTypes', null, {
      root: true,
    })
    await dispatch('pages/search/specializations/fetchSpecializations', null, {
      root: true,
    })
    await dispatch('pages/search/specializations/fetchCompetences', null, {
      root: true,
    })
    await dispatch('pages/search/profStatus/fetchProfStatuses', null, {
      root: true,
    })
  },
}
export const mutations = {
  updateSearchResultLoading(state, newStatus) {
    state.searchResultLoading = newStatus
  },
  fillSearchResults(state, searchResult) {
    state.searchResult = fillSearchResultsObject(searchResult)
  },
  clearState(state) {
    state.searchResult = cloneDeep(defaultState.searchResult)
    state.searchResultLoading = cloneDeep(defaultState.searchResultLoading)
  },
}
// const getters = {};
// export const modules = {
//   locationModule,
//   objectModule,
//   filterModule,
//   specializationsModule,
//   profStatusModule,
//   installerTypeModule,
// }

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations,
//   modules
// };
