const appConfigs = {
  local: {
    frontUrl: 'http://localhost',
    backUrl: 'https://cust.pro-stage.rhsolutions.xyz',
    backUrlLkz: 'https://pro-stage.rhsolutions.xyz',
    backUrlInstaller: 'https://pro-stage.rhsolutions.xyz',
    backUrlCatalog: 'https://pim-service.rhsolutions.xyz/api',
    // backUrl: 'https://cust.pro-dev.rhsolutions.xyz',
    forumUrl: 'https://forum.rehau.pro',
    oldPublicUrl: 'http://localhost/old',
    vkRtrgId: 'VK-RTRG-947466-1GVVJ',
    gtmId: 'GTM-N5ZDLCB5',
    ymId: '28944725',
    fbPixelId: '926296708322829',
    redirectUrl: 'http://localhost',
    keycloakUrl: 'https://sso.pro-stage.rhsolutions.xyz/auth',
    // раскомментировать для кейклока локального
    // keycloakUrl: 'http://localhost:8081/auth',
    // блог локальный
    // blogApi: 'http://localhost:8888/wp-json'
    blogApi: 'https://pro-stage.rhsolutions.xyz/blog/wp-json',
  },
  development: {
    frontUrl: 'https://pro-dev.rhsolutions.xyz',
    backUrl: 'https://cust.pro-dev.rhsolutions.xyz',
    backUrlLkz: 'https://pro-dev.rhsolutions.xyz',
    backUrlInstaller: 'https://pro-dev.rhsolutions.xyz',
    backUrlCatalog: 'https://pim-service.rhsolutions.xyz/api',
    forumUrl: 'https://forum.rehau.pro',
    oldPublicUrl: 'https://pro-dev.rhsolutions.xyz/old',
    vkRtrgId: 'VK-RTRG-947466-1GVVJ',
    gtmId: 'GTM-N5ZDLCB5',
    ymId: '28944725',
    fbPixelId: '926296708322829',
    redirectUrl: 'https://pro-dev.rhsolutions.xyz',
    keycloakUrl: 'https://sso.pro-stage.rhsolutions.xyz/auth',
    blogApi: 'https://pro-stage.rhsolutions.xyz/blog/wp-json',
  },
  staging: {
    frontUrl: 'https://pro-stage.rhsolutions.xyz',
    backUrl: 'https://cust.pro-stage.rhsolutions.xyz',
    backUrlLkz: 'https://pro-stage.rhsolutions.xyz',
    backUrlInstaller: 'https://pro-stage.rhsolutions.xyz',
    backUrlCatalog: 'https://pim-service.rhsolutions.xyz/api',
    forumUrl: 'https://forum.rehau.pro',
    oldPublicUrl: 'https://pro-stage.rhsolutions.xyz/old',
    vkRtrgId: 'VK-RTRG-947466-1GVVJ',
    gtmId: 'GTM-N5ZDLCB5',
    ymId: '28944725',
    fbPixelId: '926296708322829',
    redirectUrl: 'https://pro-stage.rhsolutions.xyz',
    keycloakUrl: 'https://sso.pro-stage.rhsolutions.xyz/auth',
    blogApi: 'https://pro-stage.rhsolutions.xyz/blog/wp-json',
  },
  production: {
    frontUrl: 'https://pro.rhsolutions.ru',
    backUrl: 'https://pro.rhsolutions.ru',
    backUrlLkz: 'https://pro.rhsolutions.ru',
    backUrlInstaller: 'https://pro.rhsolutions.ru/lk-backend',
    backUrlCatalog: 'https://pim-service.rhsolutions.ru/api',
    forumUrl: 'https://forum.pro.rhsolutions.ru',
    oldPublicUrl: 'https://pro.rhsolutions.ru/old',
    vkRtrgId: 'VK-RTRG-947466-1GVVJ',
    gtmId: 'GTM-N5ZDLCB5',
    ymId: '28944725',
    fbPixelId: '926296708322829',
    redirectUrl: 'https://pro.rhsolutions.ru',
    keycloakUrl: 'https://sso.pro.rhsolutions.ru/auth',
    blogApi: 'https://pro.rhsolutions.ru/blog/wp-json',
  },
}

/**
 * Возвращает значение конфига по имени конфига
 * @param {'frontUrl'|'backUrl'|'backUrlLkz'|'backUrlInstaller'|'backUrlCatalog'|'forumUrl'|'oldPublicUrl'|'vkRtrgId'|'gtmId'|'ymId'|'fbPixelId'|'saasUrl'|'redirectUrl'|'keycloakUrl'} configName
 * @returns {string}
 */
export function getConfig(configName) {
  const mode = process.env.NODE_ENV
  const configs = getConfigsByMode(mode)
  // console.log(`CONFIG: Name: ${configName}, Value: ${configs[configName]}`)
  return configs[configName]

  function getConfigsByMode(mode) {
    return appConfigs[mode]
  }
}
