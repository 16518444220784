const by = {
  fullTitle: 'belarus',
  key: 'by',
  codeCapitalized: 'BY',
  code: 'by',
  cityPlaceholder: 'Минск',
  countryCapitalPlaceholder: 'Беларусь, г Минск',
  searchQuery: 'Беларусь',
}
const kz = {
  key: 'kz',
  fullTitle: 'kazakhstan',
  codeCapitalized: 'KZ',
  code: 'kz',
  cityPlaceholder: 'Алма-Ата',
  countryCapitalPlaceholder: 'Казахстан, г Алма-Ата',
  searchQuery: 'Казахстан',
}

export const FOREIGN_LANDINGS_COUNTRIES = {
  by,
  kz,
}
