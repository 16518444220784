const state = () => ({
  objectTypes: [],
  object: [],
})

const actions = {
  async fetchObjectTypes({ commit }) {
    const res = await this.$axios.get(`/custapi/allrest/search/objtypes`)
    const fetchedObjectTypes = await res.data

    commit('fetchObjectTypes', fetchedObjectTypes)
  },
  updateObject({ commit, dispatch }, newObject) {
    commit('updateObject', newObject)
    // dispatch("search/seoModule/setSeoParams", null, { root: true });
  },
  clearObject({ commit }) {
    commit('clearObject')
  },
}

const mutations = {
  fetchObjectTypes(state, fetchedObjectTypes) {
    state.objectTypes = fetchedObjectTypes
  },
  updateObject(state, newObject) {
    state.object = newObject
  },
  clearObject(state) {
    state.object = []
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
