import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _066a5fc0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _e5509868 = () => interopDefault(import('../pages/ask.vue' /* webpackChunkName: "pages/ask" */))
const _66087643 = () => interopDefault(import('../pages/check-qr.vue' /* webpackChunkName: "pages/check-qr" */))
const _065b1310 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _315e241a = () => interopDefault(import('../pages/dzr-latun.vue' /* webpackChunkName: "pages/dzr-latun" */))
const _79264d8a = () => interopDefault(import('../pages/education.vue' /* webpackChunkName: "pages/education" */))
const _723f6098 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _79bb5d55 = () => interopDefault(import('../pages/mobileapp/index.vue' /* webpackChunkName: "pages/mobileapp/index" */))
const _354d6b61 = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _ae3414a2 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _19244f3c = () => interopDefault(import('../pages/quality-control.vue' /* webpackChunkName: "pages/quality-control" */))
const _ebeb0552 = () => interopDefault(import('../pages/r-bonus.vue' /* webpackChunkName: "pages/r-bonus" */))
const _4ca03df2 = () => interopDefault(import('../pages/r-garant.vue' /* webpackChunkName: "pages/r-garant" */))
const _1dfed787 = () => interopDefault(import('../pages/reliable.vue' /* webpackChunkName: "pages/reliable" */))
const _c0b96d8e = () => interopDefault(import('../pages/resheniya-dlya-domov/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/index" */))
const _4b97c0de = () => interopDefault(import('../pages/resheniya-dlya-kvartir/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/index" */))
const _1f918b25 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _e8b46c3c = () => interopDefault(import('../pages/stabil.vue' /* webpackChunkName: "pages/stabil" */))
const _6537e4b6 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _6e3b5ac6 = () => interopDefault(import('../pages/landings/automation.vue' /* webpackChunkName: "pages/landings/automation" */))
const _24d359e5 = () => interopDefault(import('../pages/landings/canal.vue' /* webpackChunkName: "pages/landings/canal" */))
const _39ec8a06 = () => interopDefault(import('../pages/landings/cottage.vue' /* webpackChunkName: "pages/landings/cottage" */))
const _413e2701 = () => interopDefault(import('../pages/landings/decisions.vue' /* webpackChunkName: "pages/landings/decisions" */))
const _12411375 = () => interopDefault(import('../pages/landings/engineer.vue' /* webpackChunkName: "pages/landings/engineer" */))
const _16e5267f = () => interopDefault(import('../pages/landings/flat.vue' /* webpackChunkName: "pages/landings/flat" */))
const _b5279514 = () => interopDefault(import('../pages/landings/floor.vue' /* webpackChunkName: "pages/landings/floor" */))
const _745be176 = () => interopDefault(import('../pages/landings/heat.vue' /* webpackChunkName: "pages/landings/heat" */))
const _00dba7c2 = () => interopDefault(import('../pages/landings/one-day.vue' /* webpackChunkName: "pages/landings/one-day" */))
const _764ff4f1 = () => interopDefault(import('../pages/landings/place.vue' /* webpackChunkName: "pages/landings/place" */))
const _e1c57106 = () => interopDefault(import('../pages/landings/radiator-heat.vue' /* webpackChunkName: "pages/landings/radiator-heat" */))
const _1f394d7f = () => interopDefault(import('../pages/landings/solution.vue' /* webpackChunkName: "pages/landings/solution" */))
const _68c7f8f8 = () => interopDefault(import('../pages/landings/solutions.vue' /* webpackChunkName: "pages/landings/solutions" */))
const _332acf38 = () => interopDefault(import('../pages/landings/townhouse.vue' /* webpackChunkName: "pages/landings/townhouse" */))
const _028d4dfe = () => interopDefault(import('../pages/landings/water.vue' /* webpackChunkName: "pages/landings/water" */))
const _3398bb01 = () => interopDefault(import('../pages/redirect/check-role.vue' /* webpackChunkName: "pages/redirect/check-role" */))
const _75b65f09 = () => interopDefault(import('../pages/redirect/register.vue' /* webpackChunkName: "pages/redirect/register" */))
const _001a8a77 = () => interopDefault(import('../pages/resheniya-dlya-domov/kanalizacziya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/kanalizacziya/index" */))
const _d90eb162 = () => interopDefault(import('../pages/resheniya-dlya-domov/kompleksnye-resheniya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/kompleksnye-resheniya/index" */))
const _a69ad6c4 = () => interopDefault(import('../pages/resheniya-dlya-domov/obogrev-otkrytykh-ploshhadok/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/obogrev-otkrytykh-ploshhadok/index" */))
const _4f0b6139 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/index" */))
const _33e78e3c = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-upravleniya-mikroklimatom/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-upravleniya-mikroklimatom/index" */))
const _7c91b88a = () => interopDefault(import('../pages/resheniya-dlya-domov/teplye-poly/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/teplye-poly/index" */))
const _1e1f9f1f = () => interopDefault(import('../pages/resheniya-dlya-domov/vodosnabzhenie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/vodosnabzhenie/index" */))
const _2e25086e = () => interopDefault(import('../pages/resheniya-dlya-domov/zashhita-ot-protechek/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/zashhita-ot-protechek/index" */))
const _662d3262 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/kanalizacziya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/kanalizacziya/index" */))
const _7931aba7 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/kompleksnye-resheniya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/kompleksnye-resheniya/index" */))
const _53507be1 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/index" */))
const _5544ce94 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-upravleniya-mikroklimatom/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-upravleniya-mikroklimatom/index" */))
const _38c86a3c = () => interopDefault(import('../pages/resheniya-dlya-kvartir/teplye-poly/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/teplye-poly/index" */))
const _29a76472 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/vodosnabzhenie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/vodosnabzhenie/index" */))
const _13de0cc6 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/zashhita-ot-protechek/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/zashhita-ot-protechek/index" */))
const _33b894cf = () => interopDefault(import('../pages/resheniya-dlya-domov/kanalizacziya/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/kanalizacziya/montazh/index" */))
const _087f8f6e = () => interopDefault(import('../pages/resheniya-dlya-domov/kanalizacziya/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/kanalizacziya/remont/index" */))
const _234f8991 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/montazh/index" */))
const _56af5b52 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/obsluzhivanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/obsluzhivanie/index" */))
const _0944e354 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/pod-klyuch/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/pod-klyuch/index" */))
const _14361086 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/proektirovanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/proektirovanie/index" */))
const _1a12b972 = () => interopDefault(import('../pages/resheniya-dlya-domov/sistema-otopleniya/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/sistema-otopleniya/remont/index" */))
const _07488605 = () => interopDefault(import('../pages/resheniya-dlya-domov/teplye-poly/montazh-elektricheskogo-pola/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/teplye-poly/montazh-elektricheskogo-pola/index" */))
const _0d900f46 = () => interopDefault(import('../pages/resheniya-dlya-domov/teplye-poly/montazh-vodyanogo-pola/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/teplye-poly/montazh-vodyanogo-pola/index" */))
const _22528177 = () => interopDefault(import('../pages/resheniya-dlya-domov/vodosnabzhenie/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/vodosnabzhenie/montazh/index" */))
const _146876a0 = () => interopDefault(import('../pages/resheniya-dlya-domov/vodosnabzhenie/pod-klyuch/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/vodosnabzhenie/pod-klyuch/index" */))
const _258d89e0 = () => interopDefault(import('../pages/resheniya-dlya-domov/vodosnabzhenie/proektirovanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/vodosnabzhenie/proektirovanie/index" */))
const _7d3bd2be = () => interopDefault(import('../pages/resheniya-dlya-domov/vodosnabzhenie/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-domov/vodosnabzhenie/remont/index" */))
const _19719927 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/kanalizacziya/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/kanalizacziya/montazh/index" */))
const _032948f1 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/kanalizacziya/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/kanalizacziya/remont/index" */))
const _52c37c39 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/montazh/index" */))
const _8614b202 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/obsluzhivanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/obsluzhivanie/index" */))
const _2158baae = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/pod-klyuch/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/pod-klyuch/index" */))
const _359350de = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/proektirovanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/proektirovanie/index" */))
const _dd3488c2 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/sistema-otopleniya/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/sistema-otopleniya/remont/index" */))
const _188befc2 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/vodosnabzhenie/montazh/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/vodosnabzhenie/montazh/index" */))
const _d78a45f0 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/vodosnabzhenie/pod-klyuch/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/vodosnabzhenie/pod-klyuch/index" */))
const _4b88b638 = () => interopDefault(import('../pages/resheniya-dlya-kvartir/vodosnabzhenie/proektirovanie/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/vodosnabzhenie/proektirovanie/index" */))
const _b1c9ca0e = () => interopDefault(import('../pages/resheniya-dlya-kvartir/vodosnabzhenie/remont/index.vue' /* webpackChunkName: "pages/resheniya-dlya-kvartir/vodosnabzhenie/remont/index" */))
const _1c82aae5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f3ab520c = () => interopDefault(import('../pages/guarantee-text/_id.vue' /* webpackChunkName: "pages/guarantee-text/_id" */))
const _57bcc527 = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _201ca805 = () => interopDefault(import('../pages/photos/_installerId/_albumId.vue' /* webpackChunkName: "pages/photos/_installerId/_albumId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _066a5fc0,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/ask/",
    component: _e5509868,
    pathToRegexpOptions: {"strict":true},
    name: "ask"
  }, {
    path: "/check-qr/",
    component: _66087643,
    pathToRegexpOptions: {"strict":true},
    name: "check-qr"
  }, {
    path: "/contacts/",
    component: _065b1310,
    pathToRegexpOptions: {"strict":true},
    name: "contacts"
  }, {
    path: "/dzr-latun/",
    component: _315e241a,
    pathToRegexpOptions: {"strict":true},
    name: "dzr-latun"
  }, {
    path: "/education/",
    component: _79264d8a,
    pathToRegexpOptions: {"strict":true},
    name: "education"
  }, {
    path: "/forum/",
    component: _723f6098,
    pathToRegexpOptions: {"strict":true},
    name: "forum"
  }, {
    path: "/mobileapp/",
    component: _79bb5d55,
    pathToRegexpOptions: {"strict":true},
    name: "mobileapp"
  }, {
    path: "/order/",
    component: _354d6b61,
    pathToRegexpOptions: {"strict":true},
    name: "order"
  }, {
    path: "/policy/",
    component: _ae3414a2,
    pathToRegexpOptions: {"strict":true},
    name: "policy"
  }, {
    path: "/quality-control/",
    component: _19244f3c,
    pathToRegexpOptions: {"strict":true},
    name: "quality-control"
  }, {
    path: "/r-bonus/",
    component: _ebeb0552,
    pathToRegexpOptions: {"strict":true},
    name: "r-bonus"
  }, {
    path: "/r-garant/",
    component: _4ca03df2,
    pathToRegexpOptions: {"strict":true},
    name: "r-garant"
  }, {
    path: "/reliable/",
    component: _1dfed787,
    pathToRegexpOptions: {"strict":true},
    name: "reliable"
  }, {
    path: "/resheniya-dlya-domov/",
    component: _c0b96d8e,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov"
  }, {
    path: "/resheniya-dlya-kvartir/",
    component: _4b97c0de,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir"
  }, {
    path: "/search/",
    component: _1f918b25,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/stabil/",
    component: _e8b46c3c,
    pathToRegexpOptions: {"strict":true},
    name: "stabil"
  }, {
    path: "/support/",
    component: _6537e4b6,
    pathToRegexpOptions: {"strict":true},
    name: "support"
  }, {
    path: "/landings/automation/",
    component: _6e3b5ac6,
    pathToRegexpOptions: {"strict":true},
    name: "landings-automation"
  }, {
    path: "/landings/canal/",
    component: _24d359e5,
    pathToRegexpOptions: {"strict":true},
    name: "landings-canal"
  }, {
    path: "/landings/cottage/",
    component: _39ec8a06,
    pathToRegexpOptions: {"strict":true},
    name: "landings-cottage"
  }, {
    path: "/landings/decisions/",
    component: _413e2701,
    pathToRegexpOptions: {"strict":true},
    name: "landings-decisions"
  }, {
    path: "/landings/engineer/",
    component: _12411375,
    pathToRegexpOptions: {"strict":true},
    name: "landings-engineer"
  }, {
    path: "/landings/flat/",
    component: _16e5267f,
    pathToRegexpOptions: {"strict":true},
    name: "landings-flat"
  }, {
    path: "/landings/floor/",
    component: _b5279514,
    pathToRegexpOptions: {"strict":true},
    name: "landings-floor"
  }, {
    path: "/landings/heat/",
    component: _745be176,
    pathToRegexpOptions: {"strict":true},
    name: "landings-heat"
  }, {
    path: "/landings/one-day/",
    component: _00dba7c2,
    pathToRegexpOptions: {"strict":true},
    name: "landings-one-day"
  }, {
    path: "/landings/place/",
    component: _764ff4f1,
    pathToRegexpOptions: {"strict":true},
    name: "landings-place"
  }, {
    path: "/landings/radiator-heat/",
    component: _e1c57106,
    pathToRegexpOptions: {"strict":true},
    name: "landings-radiator-heat"
  }, {
    path: "/landings/solution/",
    component: _1f394d7f,
    pathToRegexpOptions: {"strict":true},
    name: "landings-solution"
  }, {
    path: "/landings/solutions/",
    component: _68c7f8f8,
    pathToRegexpOptions: {"strict":true},
    name: "landings-solutions"
  }, {
    path: "/landings/townhouse/",
    component: _332acf38,
    pathToRegexpOptions: {"strict":true},
    name: "landings-townhouse"
  }, {
    path: "/landings/water/",
    component: _028d4dfe,
    pathToRegexpOptions: {"strict":true},
    name: "landings-water"
  }, {
    path: "/redirect/check-role/",
    component: _3398bb01,
    pathToRegexpOptions: {"strict":true},
    name: "redirect-check-role"
  }, {
    path: "/redirect/register/",
    component: _75b65f09,
    pathToRegexpOptions: {"strict":true},
    name: "redirect-register"
  }, {
    path: "/resheniya-dlya-domov/kanalizacziya/",
    component: _001a8a77,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-kanalizacziya"
  }, {
    path: "/resheniya-dlya-domov/kompleksnye-resheniya/",
    component: _d90eb162,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-kompleksnye-resheniya"
  }, {
    path: "/resheniya-dlya-domov/obogrev-otkrytykh-ploshhadok/",
    component: _a69ad6c4,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-obogrev-otkrytykh-ploshhadok"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/",
    component: _4f0b6139,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya"
  }, {
    path: "/resheniya-dlya-domov/sistema-upravleniya-mikroklimatom/",
    component: _33e78e3c,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-upravleniya-mikroklimatom"
  }, {
    path: "/resheniya-dlya-domov/teplye-poly/",
    component: _7c91b88a,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-teplye-poly"
  }, {
    path: "/resheniya-dlya-domov/vodosnabzhenie/",
    component: _1e1f9f1f,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-vodosnabzhenie"
  }, {
    path: "/resheniya-dlya-domov/zashhita-ot-protechek/",
    component: _2e25086e,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-zashhita-ot-protechek"
  }, {
    path: "/resheniya-dlya-kvartir/kanalizacziya/",
    component: _662d3262,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-kanalizacziya"
  }, {
    path: "/resheniya-dlya-kvartir/kompleksnye-resheniya/",
    component: _7931aba7,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-kompleksnye-resheniya"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/",
    component: _53507be1,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-upravleniya-mikroklimatom/",
    component: _5544ce94,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-upravleniya-mikroklimatom"
  }, {
    path: "/resheniya-dlya-kvartir/teplye-poly/",
    component: _38c86a3c,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-teplye-poly"
  }, {
    path: "/resheniya-dlya-kvartir/vodosnabzhenie/",
    component: _29a76472,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-vodosnabzhenie"
  }, {
    path: "/resheniya-dlya-kvartir/zashhita-ot-protechek/",
    component: _13de0cc6,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-zashhita-ot-protechek"
  }, {
    path: "/resheniya-dlya-domov/kanalizacziya/montazh/",
    component: _33b894cf,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-kanalizacziya-montazh"
  }, {
    path: "/resheniya-dlya-domov/kanalizacziya/remont/",
    component: _087f8f6e,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-kanalizacziya-remont"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/montazh/",
    component: _234f8991,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya-montazh"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/obsluzhivanie/",
    component: _56af5b52,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya-obsluzhivanie"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/pod-klyuch/",
    component: _0944e354,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya-pod-klyuch"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/proektirovanie/",
    component: _14361086,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya-proektirovanie"
  }, {
    path: "/resheniya-dlya-domov/sistema-otopleniya/remont/",
    component: _1a12b972,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-sistema-otopleniya-remont"
  }, {
    path: "/resheniya-dlya-domov/teplye-poly/montazh-elektricheskogo-pola/",
    component: _07488605,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-teplye-poly-montazh-elektricheskogo-pola"
  }, {
    path: "/resheniya-dlya-domov/teplye-poly/montazh-vodyanogo-pola/",
    component: _0d900f46,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-teplye-poly-montazh-vodyanogo-pola"
  }, {
    path: "/resheniya-dlya-domov/vodosnabzhenie/montazh/",
    component: _22528177,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-vodosnabzhenie-montazh"
  }, {
    path: "/resheniya-dlya-domov/vodosnabzhenie/pod-klyuch/",
    component: _146876a0,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-vodosnabzhenie-pod-klyuch"
  }, {
    path: "/resheniya-dlya-domov/vodosnabzhenie/proektirovanie/",
    component: _258d89e0,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-vodosnabzhenie-proektirovanie"
  }, {
    path: "/resheniya-dlya-domov/vodosnabzhenie/remont/",
    component: _7d3bd2be,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-domov-vodosnabzhenie-remont"
  }, {
    path: "/resheniya-dlya-kvartir/kanalizacziya/montazh/",
    component: _19719927,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-kanalizacziya-montazh"
  }, {
    path: "/resheniya-dlya-kvartir/kanalizacziya/remont/",
    component: _032948f1,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-kanalizacziya-remont"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/montazh/",
    component: _52c37c39,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya-montazh"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/obsluzhivanie/",
    component: _8614b202,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya-obsluzhivanie"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/pod-klyuch/",
    component: _2158baae,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya-pod-klyuch"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/proektirovanie/",
    component: _359350de,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya-proektirovanie"
  }, {
    path: "/resheniya-dlya-kvartir/sistema-otopleniya/remont/",
    component: _dd3488c2,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-sistema-otopleniya-remont"
  }, {
    path: "/resheniya-dlya-kvartir/vodosnabzhenie/montazh/",
    component: _188befc2,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-vodosnabzhenie-montazh"
  }, {
    path: "/resheniya-dlya-kvartir/vodosnabzhenie/pod-klyuch/",
    component: _d78a45f0,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-vodosnabzhenie-pod-klyuch"
  }, {
    path: "/resheniya-dlya-kvartir/vodosnabzhenie/proektirovanie/",
    component: _4b88b638,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-vodosnabzhenie-proektirovanie"
  }, {
    path: "/resheniya-dlya-kvartir/vodosnabzhenie/remont/",
    component: _b1c9ca0e,
    pathToRegexpOptions: {"strict":true},
    name: "resheniya-dlya-kvartir-vodosnabzhenie-remont"
  }, {
    path: "/",
    component: _1c82aae5,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/guarantee-text/:id?/",
    component: _f3ab520c,
    pathToRegexpOptions: {"strict":true},
    name: "guarantee-text-id"
  }, {
    path: "/profile/:id?/",
    component: _57bcc527,
    pathToRegexpOptions: {"strict":true},
    name: "profile-id"
  }, {
    path: "/photos/:installerId?/:albumId?/",
    component: _201ca805,
    pathToRegexpOptions: {"strict":true},
    name: "photos-installerId-albumId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
