export const state = () => ({
  country: '',
  type: '',
})

export const actions = {
  updateCountry({ commit }, { country }) {
    commit('updateCountry', { country })
  },
  updateType({ commit }, { type }) {
    commit('updateType', { type })
  },
  reset({ commit }) {
    commit('reset')
  },
}

export const mutations = {
  updateCountry(state, { country }) {
    state.country = country
  },
  updateType(state, { type }) {
    state.type = type
  },
  reset(state) {
    state.country = ''
    state.type = ''
  },
}

export const getters = {}
