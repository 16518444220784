import { URL_PARAMS } from '@/constants/search/url-param-constants'
import { getSeoValues, getSmartFilterSeoValueByValueId } from '@/utilities/search/methods/getSeoParamValues'

export function encodeObjectParam(objectModule) {
  if (objectModule.object?.urlParamValue) {
    return { [URL_PARAMS.object]: objectModule.object.urlParamValue }
  }
}

export function decodeObjectParam(urlParamsFromQuery, objectModule, dispatch) {
  const objectParam = urlParamsFromQuery[URL_PARAMS.object]
  if (objectParam) {
    const objectTypes = objectModule.objectTypes
    const typeObject = getTypeObjectByUrlParamValue(objectParam, objectTypes)
    dispatch('pages/search/object/updateObject', typeObject, {
      root: true,
    })
  }
}

export function computeObjectSeo(smartFilterSeoParams, objectModule) {
  const object = objectModule.object
  if (object?.value) {
    const seoParamValues = getSeoValues(URL_PARAMS.object, smartFilterSeoParams)
    const objectSeoValue = getSmartFilterSeoValueByValueId(object.valueId, seoParamValues)

    // TODO Will needed to remove then smartfilterparams will be updated
    if (!objectSeoValue) return {}

    const { h1, title, description } = objectSeoValue
    const variable = URL_PARAMS.object.toUpperCase()
    return { h1, title, description, variable }
  }
}

function getTypeObjectByUrlParamValue(urlParamValue, typeArray) {
  return typeArray.find((typeItem) => typeItem.urlParamValue === urlParamValue)
}
