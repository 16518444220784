const state = () => ({
  installerTypes: [],
})

const actions = {
  async fetchInstallerTypes({ commit }) {
    const res = await this.$axios.get(`/custapi/allrest/search/installertypes`)
    const fetchedInstallerTypes = await res.data
    commit('fetchInstallerTypes', fetchedInstallerTypes)
  },
}

const mutations = {
  fetchInstallerTypes(state, fetchedInstallerTypes) {
    state.installerTypes = fetchedInstallerTypes
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
