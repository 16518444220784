import axios from 'axios'
import { defineStore } from 'pinia'

import { getConfig } from '@/configs/app.configs'
import { clearAccessToken, getAccessToken } from '@/utilities/access-token-utility'

export const useUserStore = defineStore('user', {
  state: () => ({
    data: null,
    isLoading: true,
    userRole: '',
  }),
  actions: {
    async fetchData() {
      this.isLoading = true
      try {
        const accessToken = getAccessToken()
        if (accessToken) {
          const res = await axios.get('/custapi/rest/users/curuser', {
            baseURL: getConfig('backUrl'),
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          this.data = res.data

          this.userRole = res.data.userDomain
        }
      } catch {
        this.data = null
        clearAccessToken()
      }
      this.isLoading = false
    },
    logout() {
      clearAccessToken()
      this.data = null
    },
  },
  getters: {
    isInstaller() {
      return getAccessToken() && this.data?.userDomain === 'MONTAGE'
    },
    isClient() {
      return getAccessToken() && this.data?.userDomain === 'CLIENT'
    },
    isWorker() {
      return getAccessToken() && this.data?.userDomain === 'WORKER'
    },
    isLoggedIn() {
      return this.isInstaller || this.isClient || this.isWorker
    },
  },
})
