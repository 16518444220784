<template>
  <client-only>
    <div class="mobile-menu">
      <div class="mobile-menu__top">
        <ul v-if="!showSolutionsMenu && !showCatalogList" class="mobile-menu__list">
          <li class="mobile-menu__item">
            <div class="mobile-menu__link" @click="showCatalogList = !showCatalogList">
              <span class="mobile-menu__link-text">Каталог</span>
              <span class="mobile-menu__link-icon">
                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
          <li class="mobile-menu__item">
            <div class="mobile-menu__link" @click="clickMobileMenuLink('search')">
              <span class="mobile-menu__link-text">Найти исполнителя</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
          <li class="mobile-menu__item">
            <div class="mobile-menu__link" @click="showSolutionsMenu = !showSolutionsMenu">
              <span class="mobile-menu__link-text">Решения</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
          <li class="mobile-menu__item">
            <div class="mobile-menu__link" @click="clickMobileMenuLink('order')">
              <span class="mobile-menu__link-text">Создать заказ</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
          <li class="mobile-menu__item">
            <div class="mobile-menu__link" @click="clickMobileMenuLink('about')">
              <span class="mobile-menu__link-text">О проекте</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" :href="getConfig('frontUrl') + '/blog/'" target="_blank">
              <span class="mobile-menu__link-text">Блог</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </a>
          </li>
        </ul>
        <ul v-else-if="showSolutionsMenu" class="mobile-menu__sub-list">
          <li class="mobile-menu__item">
            <div class="mobile-menu__back" @click="showSolutionsMenu = !showSolutionsMenu">
              <span class="mobile-menu__back-icon">
                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.21564 8.00023L8.92578 13.7696L7.59785 15.1113L0.559769 8.00023L7.59785 0.889107L8.92578 2.23083L3.21564 8.00023Z"
                    fill="#E50040"
                  />
                </svg>
              </span>
              <span class="mobile-menu__link-text">Назад</span>
            </div>
          </li>
          <li v-for="(inner, key) in SOLUTIONS_SUBMENU" :key="key" class="mobile-menu__item">
            <div class="mobile-menu__link" @click="clickMobileMenuLink(inner.toName)">
              <span class="mobile-menu__link-text">{{ inner.title }}</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
        </ul>
        <ul v-else-if="showCatalogList" class="mobile-menu__sub-list">
          <li class="mobile-menu__item">
            <div class="mobile-menu__back" @click="showCatalogList = !showCatalogList">
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mobile-menu__back-icon"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.21564 8.00023L8.92578 13.7696L7.59785 15.1113L0.559769 8.00023L7.59785 0.889107L8.92578 2.23083L3.21564 8.00023Z"
                  fill="#E50040"
                />
              </svg>
              <span class="mobile-menu__link-text">Назад</span>
            </div>
          </li>
          <li v-for="category in catalogStore.menu" :key="category.id" class="mobile-menu__item">
            <div class="mobile-menu__link" @click="clickCatalogLink(category.name, category.id)">
              <span class="mobile-menu__link-text">{{ category.name }}</span>
              <span class="mobile-menu__link-icon"
                ><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4752 9.99998L0.445312 1.88679L2.31272 0L12.21 9.99998L2.31272 20L0.445312 18.1132L8.4752 9.99998Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="mobile-menu__bottom">
        <button
          v-if="!userStore.isLoading && !userStore.isLoggedIn"
          class="header__login-btn ui-btn -login"
          @click="clickHeaderLogin('PublicLogin')"
        >
          <span class="ui-btn__text">Войти</span>
          <span class="ui-btn__icon">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="#e50040" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.3904 22.6112C30.3952 21.616 29.0192 21 27.5008 21H16.5008C14.9808 21 13.6064 21.6144 12.6112 22.6112C11.616 23.6064 11 24.9824 11 26.5008V38H13V26.4992C13 25.5328 13.392 24.6576 14.0256 24.024C14.6592 23.3904 15.5328 22.9984 16.5008 22.9984H27.5008C28.4672 22.9984 29.3424 23.3904 29.976 24.024C30.6096 24.6576 31.0016 25.5312 31.0016 26.4992V38H33.0016V26.4992C33.0016 24.9808 32.3856 23.6064 31.3904 22.6112ZM22.0016 19C23.6656 19 25.328 18.3648 26.5984 17.096C27.8672 15.8272 28.5024 14.1632 28.5024 12.4992C28.5024 10.8352 27.8672 9.1712 26.5984 7.9024C25.328 6.6336 23.6656 6 22.0016 6C20.3392 6 18.6736 6.6352 17.4048 7.904C16.136 9.1744 15.5008 10.8368 15.5008 12.5008C15.5008 14.1632 16.136 15.8288 17.4048 17.0976C18.6752 18.3664 20.3376 19 22.0016 19ZM18.8192 9.3184C19.6976 8.44 20.8496 8 22.0016 8C23.1536 8 24.304 8.4384 25.184 9.3184C26.0624 10.1968 26.5024 11.3488 26.5024 12.5008C26.5024 13.6528 26.0624 14.8032 25.184 15.6832C24.304 16.56 23.1536 17 22.0016 17C20.8496 17 19.6976 16.56 18.8192 15.6816C17.9408 14.8032 17.5008 13.6512 17.5008 12.4992C17.5008 11.3472 17.9408 10.1968 18.8192 9.3184Z"
              />
            </svg>
          </span>
        </button>
        <UserLoggedIn v-if="!userStore.isLoading && userStore.isLoggedIn" reverse />
        <div v-if="userStore.isLoading">Загрузка...</div>
      </div>
    </div>
  </client-only>
</template>

<script>
// import { countHowItWorks } from "@/utils/counters-api";
// import { yandexMetrikaReachGoal } from "@/utils/yandex-metrika";
// import {gtmReachGoal} from "@/utils/vue-gtm";

import { ref, useContext, useRouter } from '@nuxtjs/composition-api'

import UserLoggedIn from '@/components/Header/HeaderUser/UserLoggedIn.vue'
import { getConfig } from '@/configs/app.configs'
// import { SOLUTIONS_CATEGORIES } from '@/constants/solutions/categories.constants.js'
import { SOLUTIONS_SUBMENU } from '@/constants/solutions/solutions.constants'
import { useCatalogStore } from '@/store/modules/catalog.store'
import { useUserStore } from '@/store/modules/user.store'
import { getLocationSearch } from '@/utilities/get-location-search.utility'
import { UrlActionsUtilities } from '@/utilities/url-actions.utilities'

export default {
  components: { UserLoggedIn },
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const { $keycloak, $gtm } = useContext()

    const clickHeaderLogin = async () => {
      await $keycloak.login({
        redirectUri: getConfig('redirectUrl') + '/redirect/check-role' + getLocationSearch(),
      })
    }

    const showSolutionsMenu = ref(false)

    const closeMenu = () => {
      const burger = document.querySelector('.burger')
      const mobileMenu = document.querySelector('.mobile-menu')
      burger.classList.remove('js-open')
      mobileMenu.classList.remove('js-open')

      document.body.classList.remove('modal-opened')

      showSolutionsMenu.value = false
      showCatalogList.value = false
    }

    const clickMobileMenuLink = (pageName) => {
      closeMenu()

      switch (pageName) {
        case 'search':
          $gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToSearch',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToSearch')
          break
        case 'order':
          $gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToOrder',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToOrder')
          break
        case 'about':
          // this.$gtm.push({
          //   event: 'reachGoal',
          //   target: 'Goals',
          //   action: 'ClickGoToHow',
          // })
          // this.$yandexMetrika.reachGoal('ClickGoToHow')
          // countHowItWorks();
          break
      }

      router.push({ name: pageName })
    }

    const catalogStore = useCatalogStore()
    const showCatalogList = ref(false)

    const clickCatalogLink = (name, id) => {
      const link = `${getConfig('frontUrl')}/catalog/${UrlActionsUtilities.translitForUrl(name)}-${id}/`

      closeMenu()

      window.location.href = link
    }

    return {
      userStore,
      clickHeaderLogin,
      getConfig,
      SOLUTIONS_SUBMENU,
      clickMobileMenuLink,
      showSolutionsMenu,
      catalogStore,
      showCatalogList,
      clickCatalogLink,
    }
  },
}
</script>

<style scoped lang="scss">
// @import "/assets/scss/index/header.scss";
.mobile-menu__link {
  text-decoration: unset;
}
</style>
