import { useRouter } from '@nuxtjs/composition-api'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { defineStore } from 'pinia'

import { getConfig } from '@/configs/app.configs'
import { getAccessToken } from '@/utilities/access-token-utility'

export const useGuaranteeStore = defineStore('guarantee', {
  state: () => ({
    id: null,
    data: null,
    isLoading: true,
    isRequestSending: false,
    serverError: '',
  }),
  actions: {
    async fetchData() {
      this.isLoading = true
      this.isRequestSending = true
      this.serverError = ''

      const token = getAccessToken()

      if (token) {
        axios
          .get(`/api/warranties/${this.id}/detail`, {
            baseURL: getConfig('backUrlInstaller'),
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            this.data = res.data
          })
          .catch((err) => {
            // при ошибке 403 - недостаточно прав - редиректим на главную
            if (err.response.status === 403) {
              useRouter().push('/')
            } else {
              this.serverError = err.response?.data?.errorText
            }
          })
      }

      this.isLoading = false
      this.isRequestSending = false
    },
  },
  getters: {
    hasData() {
      return !isEmpty(this.data)
    },
  },
})
