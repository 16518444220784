import Cookies from 'universal-cookie'

const TOKEN_KEY = 'access_token'
const cookies = new Cookies()

export function getAccessToken() {
  const token = cookies.get(TOKEN_KEY)
  if (!token || token === 'undefined') {
    return null
  } else {
    return token
  }
}
export function setAccessToken(token) {
  cookies.set(TOKEN_KEY, token, { path: '/', sameSite: true })
  // cookies.set(TOKEN_KEY, token, { path: '/' })
}
export function clearAccessToken() {
  cookies.remove(TOKEN_KEY, { path: '/', sameSite: true })
  // cookies.remove(TOKEN_KEY, { path: '/' })
}
