import { isEmpty } from 'lodash'

import { decodeAddressParam, encodeAddressParam } from '@/utilities/search/urlParams/addressUrlParam'
import { decodeCountryParam, encodeCountryParam } from '@/utilities/search/urlParams/countryUrlParam'
import { decodePaginationParam, encodePaginationParam } from '@/utilities/search/urlParams/paginationUrlParam'
import { decodeSortParam, encodeSortParam } from '@/utilities/search/urlParams/sortUrlParams'
import {
  decodeSpecializationParam,
  encodeSpecializationParam,
} from '@/utilities/search/urlParams/specializationUrlParam'

const state = () => ({})

const actions = {
  encodeUrlParams({ dispatch, rootState }) {
    const params = {}
    const searchStore = rootState.pages.search

    const countryParam = encodeCountryParam(searchStore.location)
    accumulateParams(params, countryParam)
    const addressParam = encodeAddressParam(searchStore.location)
    accumulateParams(params, addressParam)

    // const objectParam = encodeObjectParam(searchStore.object)
    // accumulateParams(params, objectParam)

    // const experienceParam = encodeExperienceParam(searchStore.filter)
    // accumulateParams(params, experienceParam)

    // const feedbacksParam = encodeFeedbacksParam(searchStore.filter)
    // accumulateParams(params, feedbacksParam)

    // const installerTypeParam = encodeInstallerTypeParam(
    //   searchStore.filter,
    //   searchStore.installerType
    // )
    // accumulateParams(params, installerTypeParam)

    const specializationsParam = encodeSpecializationParam(searchStore.filter, searchStore.specializations)
    accumulateParams(params, specializationsParam)

    // const profStatusParamValue = encodeProfStatusParam(
    //   searchStore.filter,
    //   searchStore.profStatus
    // )
    // accumulateParams(params, profStatusParamValue)

    const sortParam = encodeSortParam(searchStore.sort)
    accumulateParams(params, sortParam)

    const pageParam = encodePaginationParam(searchStore.sort)
    accumulateParams(params, pageParam)
    this.$router.push({ name: 'search', query: params })
  },
  async decodeUrlParams({ rootState, dispatch }, urlParamsFromQuery) {
    if (!isEmpty(urlParamsFromQuery)) {
      await decodeCountryParam(urlParamsFromQuery, dispatch)
      await decodeAddressParam(urlParamsFromQuery, dispatch)

      // decodeObjectParam(
      //   urlParamsFromQuery,
      //   rootState.pages.search.object,
      //   dispatch
      // )

      // decodeExperienceParam(urlParamsFromQuery, dispatch)
      //
      // decodeFeedbacksParam(urlParamsFromQuery, dispatch)

      // decodeInstallerTypeParam(
      //   urlParamsFromQuery,
      //   rootState.pages.search.installerType,
      //   dispatch
      // )

      decodeSpecializationParam(urlParamsFromQuery, rootState.pages.search.specializations, dispatch)

      // decodeProfStatusParam(
      //   urlParamsFromQuery,
      //   rootState.pages.search.profStatus,
      //   dispatch
      // )

      decodeSortParam(urlParamsFromQuery, dispatch)

      decodePaginationParam(urlParamsFromQuery, dispatch)
    }
  },
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function accumulateParams(accumulatorObject, objectForAccumulate) {
  if (!isEmpty(objectForAccumulate)) {
    Object.assign(accumulatorObject, objectForAccumulate)
  }
}
