const ORDER_KEY = 'order'

export class OrderCookie {
  static get() {
    if (process.browser) {
      const res = localStorage.getItem(ORDER_KEY)
      if (res) {
        const parsed = JSON.parse(res)
        const isExpired = this.getIsExpired(parsed.timestamp)
        if (!isExpired) {
          return parsed.data
        } else {
          this.clear()
        }
      }
    }
  }

  static set(data) {
    if (process.browser && data) {
      const timestamp = Date.now()
      localStorage.setItem(
        ORDER_KEY,
        JSON.stringify({
          data,
          timestamp,
        }),
      )
    }
  }

  static clear() {
    localStorage.removeItem(ORDER_KEY)
  }

  static getIsExpired(timestamp) {
    const now = Date.now()
    const oneDayMilliseconds = 24 * 60 * 60 * 1000
    return now > timestamp + oneDayMilliseconds
  }
}
