import { cloneDeep, isEmpty, sortBy } from 'lodash'
import { defineStore } from 'pinia'

import {
  DEFAULT_ORDER_FORM,
  FIRST_STEP,
  LAST_STEP,
  ORDER_SELECTED_INSTALLERS_MAX_AMOUNT,
  ORDER_TITLES,
} from '@/constants/order.constants'
import { OrderProvider } from '@/providers/order.provider'
import { OrderCookie } from '@/utilities/order-cookies.utility'
import { retardedPagination } from '@/utilities/pagination.utility'
import { scrollToTop } from '@/utilities/scroll-to-top'

export const useOrderStore = defineStore('order', {
  state: () => ({
    data: {
      specializations: [],
      existObjects: [],
      objectTypes: [],
      additionalWorks: [],
      titles: ORDER_TITLES,
      installers: {
        list: [],
        pagination: {
          currentPage: 1,
          total: 0,
          firstItem: 0,
          lastItem: 0,
          lastPage: 0,
        },
      },
      availableSelectedInstallers: [],
      nameExamples: [],
    },
    form: DEFAULT_ORDER_FORM,
    isLoading: {
      init: true,
      specializations: false,
      objectTypes: false,
      additionalWorks: false,
      installers: false,
      defaultSelectedInstallers: false,
      availableSelectedInstallers: false,
      nameExamples: false,
    },
    createdRequestId: null,
  }),
  actions: {
    async init(selectedInstallers) {
      this.isLoading.init = true
      const hasSelectedInstallers = selectedInstallers?.length
      // достаем form из локалсторадж
      const formFromLocalStorage = OrderCookie.get()
      if (formFromLocalStorage) {
        if (hasSelectedInstallers) {
          // && !isEqual(
          //   selectedInstallers,
          //   formFromLocalStorage.selectedInstallers.map((item) => String(item.id)),
          // )
          // если локалсторадж не пустой, но были выбраны монтажники, сравниваем их,
          // и если монтажники новые устанавливаем дефолтную форм
          this.setDefaultForm()
        } else {
          this.form = formFromLocalStorage
        }
      } else {
        // если нет, дефолтную форму
        this.setDefaultForm()
      }
      if (hasSelectedInstallers) {
        if (typeof selectedInstallers === 'string') {
          selectedInstallers = [selectedInstallers]
        }
        const installersInfo = await OrderProvider.getInstallersInfo(selectedInstallers)
        this.form.selectedInstallers = installersInfo.map((item) => {
          return { ...item, manuallySelected: true, fromSearchSelected: true }
        })
      }
      await this.fetchInstallers()
      await this.fetchAvailableSelectedInstallers()
      OrderCookie.set(this.form)
      this.isLoading.init = false
    },
    setDefaultForm() {
      this.form = cloneDeep(DEFAULT_ORDER_FORM)
    },
    async fetchSpecializations() {
      this.isLoading.specializations = true

      const joinedRes = await retardedPagination({
        request: OrderProvider.getSpecializations,
        totalPagesField: 'lastPage',
      })
      this.data.specializations = joinedRes.map((joinedResItem) => joinedResItem.content).flat()

      this.isLoading.specializations = false
    },
    async fetchObjectTypes() {
      this.isLoading.objectTypes = true

      const joinedRes = await retardedPagination({
        request: OrderProvider.getObjectTypes,
        totalPagesField: 'lastPage',
      })
      this.data.objectTypes = joinedRes.map((joinedResItem) => joinedResItem.content).flat()

      this.isLoading.objectTypes = false
    },
    async fetchExistObjects() {
      this.isLoading.objectTypes = true

      const joinedRes = await retardedPagination({
        request: OrderProvider.getExistObjects,
        totalPagesField: 'lastPage',
      })
      this.data.existObjects = joinedRes.map((joinedResItem) => joinedResItem.content).flat()

      this.isLoading.objectTypes = false
    },
    async fetchAdditionalWorks() {
      this.isLoading.additionalWorks = true

      const joinedRes = await retardedPagination({
        request: OrderProvider.getAdditionalWorks,
        totalPagesField: 'lastPage',
      })
      this.data.additionalWorks = joinedRes.map((joinedResItem) => joinedResItem.content).flat()

      this.isLoading.additionalWorks = false
    },
    async fetchInstallers() {
      this.isLoading.installers = true

      const res = await OrderProvider.getInstallers(this.form, this.data.installers.pagination)
      this.data.installers.list = res.content
      this.data.installers.pagination.total = res.total
      this.data.installers.pagination.firstItem = res.firstItem
      this.data.installers.pagination.lastItem = res.lastItem
      this.data.installers.pagination.lastPage = res.lastPage

      this.isLoading.installers = false
    },
    async fetchDefaultSelectedInstallers() {
      this.isLoading.defaultSelectedInstallers = true

      const res = await OrderProvider.getInstallers(this.form, this.data.installers.pagination, 'selected')
      let autoSelected = res.content.map((item) => {
        return { ...item, manuallySelected: false }
      })

      if (this.manuallySelectedInstallers?.length) {
        // Удаляем повторяющихся челов
        const manuallySelectedInstallersIds = this.manuallySelectedInstallers.map((item) => item.performerId)
        const filteredAutoSelected = autoSelected.filter(
          (item) => !manuallySelectedInstallersIds.includes(item.performerId),
        )

        // Лимит в 5 человек по требованию
        const totalSlotsForAutoSelected = ORDER_SELECTED_INSTALLERS_MAX_AMOUNT - this.manuallySelectedInstallers.length
        const limitedAutoSelected = filteredAutoSelected.slice(0, totalSlotsForAutoSelected)

        autoSelected = [...this.manuallySelectedInstallers, ...limitedAutoSelected]
      } else {
        autoSelected = autoSelected.slice(0, 5)
      }

      const seenIds = new Set()
      const uniqueAutoSelected = autoSelected.filter((item) => {
        if (!seenIds.has(item.performerId)) {
          seenIds.add(item.performerId)
          return true
        }
        return false
      })

      this.form.selectedInstallers = uniqueAutoSelected

      this.data.availableSelectedInstallers = [...seenIds]

      this.isLoading.defaultSelectedInstallers = false
    },
    async fetchAvailableSelectedInstallers() {
      const manuallySelectedInstallers = this.form.selectedInstallers.filter((item) => item.manuallySelected)
      if (manuallySelectedInstallers.length) {
        this.isLoading.availableSelectedInstallers = true
        this.data.availableSelectedInstallers = await OrderProvider.getInstallers(
          this.form,
          this.data.installers.pagination,
          'available',
        )
        this.isLoading.availableSelectedInstallers = false
      }
    },
    getChecked(id, type) {
      const foundItems = this.form[type].find((item) => item.id === id)
      return Boolean(foundItems)
    },
    toggleChecked(id, type) {
      const isChecked = this.getChecked(id, type)
      if (isChecked) {
        this.form[type] = this.form[type].filter((item) => item.id !== id)
      } else {
        const itemForAdd = this.data[type].find((item) => item.id === id)
        this.form[type].push(itemForAdd)
      }
    },
    addLink() {
      let newId = 1
      if (this.form.links.length) {
        const ids = this.form.links.map((link) => {
          return Number(link.id)
        })
        const maxId = Math.max(...ids)
        newId = maxId + 1
      }
      const newLink = { id: newId, name: '', url: '' }
      this.form.links.push(newLink)
    },
    removeLink(id) {
      this.form.links = this.form.links.filter((link) => link.id !== id)
    },
    checkIsActiveInstallersVariant(variant) {
      return this.form.installersVariant === variant
    },
    async changeInstallersPage(action) {
      if (action === 'decrease') {
        if (this.data.installers.pagination.currentPage > 1) {
          this.data.installers.pagination.currentPage -= 1
          scrollToTop()
          await this.fetchInstallers()
        }
      }
      if (action === 'increase') {
        if (this.data.installers.pagination.currentPage < this.data.installers.pagination.lastPage) {
          this.data.installers.pagination.currentPage += 1
          scrollToTop()
          await this.fetchInstallers()
        }
      }
    },
    checkIsSelectedInstaller(performerId) {
      return Boolean(this.form.selectedInstallers.find((installer) => installer.performerId === performerId))
    },
    addToSelectedInstaller(performerId) {
      if (this.canAddToSelectedInstaller) {
        const foundInstaller = this.data.installers.list.find((installer) => installer.performerId === performerId)
        foundInstaller.manuallySelected = true
        this.form.selectedInstallers.push(foundInstaller)
        this.data.availableSelectedInstallers.push(foundInstaller.performerId)
      }
    },
    removeFromSelectedInstaller(performerId) {
      this.form.selectedInstallers = this.form.selectedInstallers.filter(
        (installer) => installer.performerId !== performerId,
      )
      this.data.availableSelectedInstallers = this.data.availableSelectedInstallers.filter(
        (item) => item !== performerId,
      )
    },
    checkIsAvailableSelectedInstaller(performerId) {
      const foundInstaller = this.data.availableSelectedInstallers.find((item) => item === performerId)
      return Boolean(foundInstaller)
    },
    async fetchNameExamples() {
      this.isLoading.nameExamples = true

      this.data.nameExamples = await OrderProvider.getNameExamples(this.form)

      this.isLoading.nameExamples = false
    },
    setNameFromExample(nameExample) {
      this.form.name = nameExample
    },
    goBack() {
      if (this.canGoBack) {
        this.form.step -= 1
        scrollToTop()
        // сохраняем в локалсторадж при каждом переходе на шаг назад
        OrderCookie.set(this.form)
      }
    },
    goNext() {
      if (this.canGoNext) {
        this.form.step += 1
        scrollToTop()
        // сохраняем в локалсторадж при каждом переходе на следующий шаг
        OrderCookie.set(this.form)
      }
    },
    cancel() {
      // очищаем локалсторадж
      OrderCookie.clear()
      // устанавливаем дефолтную форму
      this.form = cloneDeep(DEFAULT_ORDER_FORM)
    },
    saveBeforeLoginAsClient() {
      // Устанавливаем это поле для того чтобы при входе в лк заказчика заявка отправилась на сохранение
      this.form.isNeedToSaveAfterLoginAsClient = true
      OrderCookie.set(this.form)
    },
    async save(type) {
      const res = await OrderProvider.save(this.form, type)
      if (res) {
        OrderCookie.clear()
        return res
      }
    },
    checkIsLinkValid(link) {
      if (!link) return true

      const regExp = new RegExp('^(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})')
      return regExp.test(link)
    },
  },
  getters: {
    currentTitle() {
      return this.data.titles.find((title) => title.step === this.form.step)
    },
    isShowGoBack() {
      const isFirstPage = this.form.step === FIRST_STEP
      return !isFirstPage
    },
    progressCompletedSteps() {
      const stepModifier = 1
      return this.form.step - stepModifier
    },
    progressTotalSteps() {
      const stepModifier = 1
      return LAST_STEP - stepModifier
    },
    canAddToSelectedInstaller() {
      return this.form.selectedInstallers.length < ORDER_SELECTED_INSTALLERS_MAX_AMOUNT
    },
    progress() {
      const maxProgress = 100
      const progressPerStep = maxProgress / this.progressTotalSteps

      return Math.floor(this.progressCompletedSteps * progressPerStep)
    },
    isValidLinks() {
      const isValid = this.form.links.every((link) => {
        return Boolean(link.name) && Boolean(link.url) && this.checkIsLinkValid(link.url)
      })
      return isValid
    },
    canGoNext() {
      const hasInstallersLength = Boolean(this.data.installers.list.length)
      switch (this.form.step) {
        case 1: {
          const hasChooseSpecializations = this.form.specializations.length >= 1
          return hasChooseSpecializations && hasInstallersLength && this.hasAvailableSelectedInstallers
        }
        case 2: {
          if (this.form.objectVariant === 'exist') {
            const hasExistObject = Boolean(this.form.existObject.id)
            return hasInstallersLength && hasExistObject && this.hasAvailableSelectedInstallers
          }
          if (this.form.objectVariant === 'new') {
            const hasObjectTypes = !isEmpty(this.form.newObject.objectTypes)
            const hasObjectArea = Boolean(this.form.newObject.objectArea)
            const hasLocation = !isEmpty(this.form.newObject.location)
            return (
              hasInstallersLength &&
              hasObjectTypes &&
              hasObjectArea &&
              hasLocation &&
              this.hasAvailableSelectedInstallers
            )
          }
          break
        }
        case 3: {
          return hasInstallersLength && this.hasAvailableSelectedInstallers
        }
        case 4: {
          return hasInstallersLength && this.hasAvailableSelectedInstallers
        }
        case 5: {
          return hasInstallersLength && this.hasAvailableSelectedInstallers && this.isValidLinks
        }
        case 6: {
          return hasInstallersLength && this.hasAvailableSelectedInstallers
        }
        case 7: {
          const hasSelectedInstallers = Boolean(this.form.selectedInstallers.length)
          return hasInstallersLength && hasSelectedInstallers && this.hasAvailableSelectedInstallers
        }
        case 8: {
          const hasSelectedInstallers = Boolean(this.form.selectedInstallers.length)
          return Boolean(this.form.name) && hasSelectedInstallers && this.hasAvailableSelectedInstallers
        }
      }
      return false
    },
    canGoBack() {
      return Boolean(this.data.installers.list.length) && this.hasAvailableSelectedInstallers
    },
    selectedInstallersWithAvailable() {
      const installers = this.form.selectedInstallers.map((item) => {
        return {
          ...item,
          isAvailable: this.checkIsAvailableSelectedInstaller(item.performerId),
        }
      })
      return sortBy(installers, (item) => !item.manuallySelected)
    },
    manuallySelectedInstallers() {
      return this.selectedInstallersWithAvailable.filter((item) => item.manuallySelected)
    },
    hasAvailableSelectedInstallers() {
      if (this.manuallySelectedInstallers.length) {
        const foundInstallers = this.manuallySelectedInstallers.filter((item) => item.isAvailable)
        return Boolean(foundInstallers.length)
      } else {
        return true
      }
    },
  },
})
