import axios from 'axios'

import { dadataConfig } from '@/configs/dadata-config'

export const dadataAxiosInstance = axios.create({
  baseURL: dadataConfig.url,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Authorization: dadataConfig.token,
    'content-type': 'application/json',
    Accept: 'application/json',
  },
})

export async function searchPlaces(searchQuery) {
  const params = getDadataParamsStringify(searchQuery)
  const res = await dadataAxiosInstance.post('', params, {})
  return res.data.suggestions
}

function getDadataParamsStringify(searchQuery) {
  const params = {
    locations: [
      {
        country: 'Россия',
      },
      {
        country: 'Беларусь',
      },
      {
        country: 'Казахстан',
      },
    ],
    restrict_value: false,
    query: searchQuery,
    count: 10,
  }
  return JSON.stringify(params)
}
