import axios from 'axios'
import { defineStore } from 'pinia'

import { getConfig } from '@/configs/app.configs'
import { UrlActionsUtilities } from '@/utilities/url-actions.utilities'

export const useCatalogStore = defineStore('catalog', {
  state: () => ({
    menu: [],
  }),
  actions: {
    async getCatalogMenu() {
      await axios
        .get('/categories', {
          baseURL: getConfig('backUrlCatalog'),
        })
        .then((res) => {
          this.menu = this.setItemsLink(res.data)
        })
    },
    setItemsLink(arr) {
      if (!arr) return null

      arr.forEach((el) => {
        el.fullPath = `${getConfig('frontUrl')}/catalog/${UrlActionsUtilities.translitForUrl(el.name)}-${el.id}/`

        if (!el.children.length) return

        el.children?.forEach((childEl) => {
          childEl.fullPath = `${el.fullPath}${UrlActionsUtilities.translitForUrl(childEl.name)}-${childEl.id}/`
        })
      })

      return arr
    },
  },
})
