import { getConfig } from '@/configs/app.configs'

export function isOrganizationOwner(installer) {
  return installer.userType === 'Y' && installer.orgStatus === 'O'
}

export function getInstallerName(installer) {
  if (installer.userType === 'Y' && installer.orgStatus === 'O') {
    return installer.orgName
  } else if (installer.userType === 'P') {
    return installer.fullName
  } else {
    return installer.fullName
  }
}

export function getOrganizationTrademark(installer) {
  if (installer.trademark) {
    return installer.trademark
  }
  return installer.orgName
}

export function getLocationName(location) {
  if (!location) {
    return 'Москва и московская область'
  } else {
    return location
  }
}

export function getPhotoURL(photoId, width = 1024, height = 1024) {
  if (photoId) {
    // По ссылке с приставкой getBaseUrl ответ "200" но фото не приходит
    // url = getBaseUrl() + '/liveimg/' + photoId + '?w=' + width + '&h=' + height + '&mode=crop&scale=both&format=jpeg'
    return (
      getConfig('backUrl') +
      '/liveimg/' +
      photoId +
      '?w=' +
      width +
      '&h=' +
      height +
      '&mode=crop&scale=both&format=jpeg'
    )
  } else {
    return '/images/emptyinstaller.svg'
  }
}

export function getInstallerTrademarkOrName(installer) {
  if (isOrganizationOwner(installer)) {
    return getOrganizationTrademark(installer)
  } else {
    return getInstallerName(installer)
  }
}

export function getYearsOfWork(years) {
  if (years < 1) {
    return 'меньше года'
  } else if (years >= 1 && years < 2) {
    return 'более года'
  } else if (years >= 2 && years < 5) {
    return 'более ' + years + ' лет'
  } else if (years >= 5) {
    return 'более ' + years + ' лет'
  }
}
