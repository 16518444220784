import { COMPANY } from '@/constants/global.constant'
import { extractDefaultSeoParams } from '@/utilities/search/methods/getDefaultSeoParams'

const state = () => ({
  currentSeoParams: {
    h1: '',
    description: '',
    title: '',
  },
})
const mutations = {
  setParams(state, h1) {
    state.currentSeoParams.title = h1
    state.currentSeoParams.h1 = h1
    state.currentSeoParams.description =
      h1 + ` на сайте ${COMPANY.fullName}. Клуб сертифицированных монтажников ${COMPANY.name}`
  },
  updateSeoParams(state, seoParams) {
    state.currentSeoParams = seoParams
  },
}
const actions = {
  async fetchDefaultSeoParams({ commit }, page) {
    const res = await this.$axios.$get('/custapi/allrest/contents/listcontents', {
      params: { contPage: page, page: '1' },
    })
    const extractedParams = extractDefaultSeoParams(res)
    commit('updateSeoParams', extractedParams)
  },
  setSeoParams({ commit }, h1) {
    commit('setParams', h1)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
