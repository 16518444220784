export const state = () => ({
  id: 999,
  userDomain: '',
  fullName: '',
  roles: [],
  authType: '',
  loginName: '',
  phone: '',
  locations: [],
})

export const mutations = {
  setUserAction(state, newValue) {
    state.message = newValue
  },
  clearUserAction(state) {
    state.message = ''
  },
  updateUserData(state, data) {
    state.id = data.id
    state.userDomain = data.userDomain
    state.fullName = data.fullName
    state.roles = data.roles
    state.authType = data.authType
    state.loginName = data.loginName
    state.phone = data.phone
    state.locations = data.locations
  },
  setUserId(state, id) {
    state.id = id
  },
}

export const actions = {
  setUserAction({ commit }, { newValue }) {
    commit('setUserAction', { newValue })
  },
  clearUserAction({ commit }) {
    commit('clearUserAction')
  },
  updateUserData({ commit }, data) {
    commit('updateUserData', data)
  },
  setUserId({ commit }, id) {
    commit('updateUserId', id)
  },
}
export const getters = {
  isClient: (state) => {
    return state.roles.includes('CLIENT_USER')
  },
}
