const state = () => ({
  location: [],
  startDate: '',
  budget: '',
})

const actions = {
  updateDetailsField({ commit }, { fieldName, fieldValue }) {
    commit('updateDetailsField', { fieldName, fieldValue })
  },
}

const mutations = {
  updateDetailsField(state, { fieldName, fieldValue }) {
    state[fieldName] = fieldValue
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
