import moment from 'moment'

export function convertDateToStringDMY(date, fromInput, toFormat) {
  if (date) {
    const m = moment(date, fromInput || 'DD.MM.YYYY')
    return m.format(toFormat || 'DD-MM-YYYY')
  } else {
    return null
  }
}

export function usDateFormatTimeToISO(date) {
  const [datePart, timePart] = date.split(' ')
  const [day, month, year] = datePart.split('.')
  return `${year}-${month}-${day}T${timePart}`
}
