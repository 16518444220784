import { PAGE_QUERY_PARAM } from '@/constants/search/search-constants'

export function encodePaginationParam(sortModule) {
  const { page } = sortModule
  if (page > 1) {
    return { [PAGE_QUERY_PARAM]: page }
  }
}
export function decodePaginationParam(urlParamsFromQuery, dispatch) {
  const pageParam = urlParamsFromQuery[PAGE_QUERY_PARAM]
  if (pageParam) {
    dispatch('pages/search/sort/updatePage', Number(pageParam), {
      root: true,
    })
  }
}
