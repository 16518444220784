import CyrillicToTranslit from 'cyrillic-to-translit-js'

import { URL_PARAMS } from '@/constants/search/url-param-constants'
import { searchPlaces } from '@/utilities/dadata-utility'
import { extractSeoParam } from '@/utilities/search/methods/extractSeoParam'
import { getSeoValues } from '@/utilities/search/methods/getSeoParamValues'
const cyrillicToTranslit = new CyrillicToTranslit()

export function encodeAddressParam(locationModule) {
  const address = locationModule.location?.data?.city
  if (address) {
    const addressToEng = cyrillicToTranslit.transform(address, '-')
    return { [URL_PARAMS.address]: addressToEng.toLowerCase() }
  }
}
export async function decodeAddressParam(urlParamsFromQuery, dispatch) {
  const addressParam = urlParamsFromQuery[URL_PARAMS.address]
  if (addressParam) {
    const res = await searchPlaces(addressParam)
    if (res) {
      const resLocation = res[0]
      dispatch('pages/search/location/updateLocation', resLocation, {
        root: true,
      })
    }
  }
}
export function computeAddressSeo(smartFilterSeoParams, locationModule) {
  const address = locationModule.location.data?.city
  if (address) {
    const seoParamValues = getSeoValues(URL_PARAMS.address, smartFilterSeoParams)
    const addressSeoValue = seoParamValues[0]
    const { h1, title, description } = extractSeoParam(addressSeoValue, address)
    const variable = URL_PARAMS.address.toUpperCase()
    return { h1, title, description, variable }
  }
}
