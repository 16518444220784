const state = () => ({
  chosenInstallers: [],
})

const actions = {
  addChosenInstaller({ commit }, installer) {
    commit('addChosenInstaller', installer)
  },
  removeChosenInstaller({ commit }, id) {
    commit('removeChosenInstaller', id)
  },
  updateChosenInstaller({ commit }, actualChosenInstallers) {
    commit('updateChosenInstaller', actualChosenInstallers)
  },
}

const mutations = {
  addChosenInstaller(state, installer) {
    if (!state.chosenInstallers.some((e) => e.performerId === installer.performerId)) {
      state.chosenInstallers.push(installer)
    }
  },
  removeChosenInstaller(state, performerId) {
    state.chosenInstallers = state.chosenInstallers.filter(function (value) {
      return value.performerId !== performerId
    })
  },
  updateChosenInstaller(state, actualChosenInstallers) {
    state.chosenInstallers = actualChosenInstallers
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
