<template>
  <div class="footer-bottom__row">
    <div class="footer-bottom__col -policy">
      <ul class="footer-bottom__policy-list">
        <li class="footer-bottom__policy-item">
          <NuxtLink to="/agreement.pdf" class="footer-bottom__policy-link" target="_blank">
            Пользовательское соглашение
          </NuxtLink>
        </li>
        <li class="footer-bottom__policy-item">
          <a href="https://rhsolutions.ru/confidentiality/" class="footer-bottom__policy-link" target="_blank">
            Политика конфиденциальности
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-bottom__col -links">
      <ul class="footer-bottom__links">
        <li class="footer-bottom__links-item">
          <a
            :href="COMPANY.links.mainWithHttpsPrefix"
            target="_blank"
            class="footer-bottom__links-link"
            @click="clickFooterLink('rehauRu')"
          >
            {{ COMPANY.links.main }}
          </a>
        </li>
        <!-- <li class="footer-bottom__links-item">
          <a
            href="https://rehau-kitchen.ru"
            target="_blank"
            class="footer-bottom__links-link"
            @click="clickFooterLink('rehauKitchenRu')"
          >
            rehau-kitchen.ru</a
          >
        </li> -->
        <!--        <li class="footer-bottom__links-item">-->
        <!--          <a-->
        <!--            href="https://shop-rehau.ru"-->
        <!--            target="_blank"-->
        <!--            class="footer-bottom__links-link"-->
        <!--            @click="clickFooterLink('shopRehauRu')"-->
        <!--            >shop-rehau.ru</a-->
        <!--          >-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'FooterLink',
  data() {
    return { COMPANY }
  },
  methods: {
    clickFooterLink(pageName) {
      switch (pageName) {
        case 'rehauRu':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickFooterLinkRehauRu',
          })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkRehauRu')
          break
        case 'rehauCom':
          // this.$gtm.push({
          //   event: 'reachGoal',
          //   target: 'Goals',
          //   action: 'ClickFooterLinkRehauCom',
          // })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkRehauCom')
          break
        case 'rehauKitchenRu':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickFooterLinkRehauKitchenRu',
          })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkRehauKitchenRu')
          break
        case 'shopRehauRu':
          // this.$gtm.push({
          //   event: 'reachGoal',
          //   target: 'Goals',
          //   action: 'ClickFooterLinkShopRehauRu',
          // })
          // this.$yandexMetrika.reachGoal('ClickFooterLinkShopRehauRu')
          break
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
