export class InitMetabotUtilities {
  static setWidget(role = 'guest', isMain = true) {
    const body = document.body

    // чистим скрипты модуля, чтобы не плодить
    const currentScript = document.querySelector('#init-metabot')
    if (currentScript) {
      body.removeChild(currentScript)
    }

    // чистим уже существующие виджеты
    const currentWidgets = document.querySelectorAll('[id^="metabot-"]')
    currentWidgets?.forEach((el) => {
      body.removeChild(el)
    })

    // выбор функции инициализации в зависимости от страницы
    const innerScript = isMain
      ? `import { initMain } from 'https://user43148.clients-cdnnow.ru/js/chatwidget/pro.rhsolutions/rhsolutionMainMobile.js'
    initMain('${role}')`
      : `import { initMobile } from 'https://user43148.clients-cdnnow.ru/js/chatwidget/pro.rhsolutions/rhsolutionMainMobile.js'
    initMobile('${role}')`

    const script = document.createElement('script')
    script.id = 'init-metabot'
    script.type = 'module'
    script.innerHTML = innerScript

    body.appendChild(script)
  }

  static convertUserDomain(userDomain) {
    let role

    switch (userDomain) {
      case 'MONTAGE':
        role = 'montage'
        break
      case 'CLIENT':
        role = 'customer'
        break
      case 'WORKER':
        role = 'employee'
        break
      default:
        role = 'guest'
        break
    }

    return role
  }

  static setWidgetStatus(code) {
    sessionStorage.setItem(
      'CUSTOM_EVENT_DATASET',
      JSON.stringify({
        status: code,
      }),
    )
  }
}
