export default function checkIsOnlyOneParam(...params) {
  let countParams = 0
  for (const param of params) {
    if (typeof param === 'object') {
      for (const field in param) {
        if (param[field]) countParams += 1
      }
    }

    if (typeof param === 'boolean') {
      if (param) countParams += 1
    }
  }
  return countParams === 1
}
