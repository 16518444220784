import {
  DEFAULT_SORT_MODE,
  ORDER_PARAM_ASC,
  ORDER_PARAM_DESC,
  ORDER_QUERY_PARAM,
  SORT_PARAM_EXPERIENCE,
  SORT_PARAM_RESPONSE_SPEED,
  SORT_QUERY_PARAM,
} from '@/constants/search/search-constants'

export function encodeSortParam(sortModule) {
  const { sortMode } = sortModule
  if (sortMode !== DEFAULT_SORT_MODE) {
    switch (sortMode) {
      case 7:
        return { sort: SORT_PARAM_EXPERIENCE, order: ORDER_PARAM_DESC }
      case 8:
        return { sort: SORT_PARAM_EXPERIENCE, order: ORDER_PARAM_ASC }
      case 9:
        return { sort: SORT_PARAM_RESPONSE_SPEED, order: ORDER_PARAM_ASC }
      case 10:
        return { sort: SORT_PARAM_RESPONSE_SPEED, order: ORDER_PARAM_DESC }
    }
  }
}

export function decodeSortParam(urlParamsFromQuery, dispatch) {
  const sortParam = urlParamsFromQuery[SORT_QUERY_PARAM]
  const orderParam = urlParamsFromQuery[ORDER_QUERY_PARAM]
  if (sortParam && orderParam) {
    if (sortParam === SORT_PARAM_RESPONSE_SPEED) {
      if (orderParam === ORDER_PARAM_ASC) {
        dispatch('pages/search/sort/updateSortMode', 9, { root: true })
      }
      if (orderParam === ORDER_PARAM_DESC) {
        dispatch('pages/search/sort/updateSortMode', 10, { root: true })
      }
    }
    if (sortParam === SORT_PARAM_EXPERIENCE) {
      if (orderParam === ORDER_PARAM_ASC) {
        dispatch('pages/search/sort/updateSortMode', 8, { root: true })
      }
      if (orderParam === ORDER_PARAM_DESC) {
        dispatch('pages/search/sort/updateSortMode', 7, { root: true })
      }
    }
  }
}
