<template>
  <div>
    <Header />
    <Nuxt />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Header from '@/components/Header/Header'

export default {
  components: { Header, Footer },
}
</script>

<style lang="scss">
@import '/assets/scss/general.scss';
@import '/assets/scss/utilities.scss';
</style>
